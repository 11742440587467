import Spec from './spec.yml';
const initSchema = Spec.paths['/published'].get.responses[200].schema;
const detailSchema = Spec.paths['/published/{house_id}'].get.responses[200].schema;
const pricesSchema = Spec.paths['/loadPrices'].post.responses[200].schema;
const calendarSchema = Spec.paths['/loadCalendar'].post.responses[200].schema;
const bookRequestSchema = Spec.paths['/book'].post.parameters[0].schema;
const bookResponseSchema = Spec.paths['/book'].post.responses[200].schema;
const tentativeReservation = Spec.paths['/tentative-reservation'].post.responses[200].schema;
const verifyOTTSchema = Spec.paths['/verify-one-time-token'].post.responses[200].schema;
const reservationDetailSchema = Spec.paths['/loadReservation'].post.responses[200].schema;
const reservationListSchema = Spec.paths['/loadReservationList'].post.responses[200].schema;
const changeScheduleSchema = Spec.paths['/changeSchedule'].post.parameters[0].schema;
const cancelScheduleSchema = Spec.paths['/cancelSchedule'].post.parameters[0].schema;
const loadHouseSchema = Spec.paths['/loadHouse'].get.responses[200].schema;
const loadHostSchema = Spec.paths['/loadHost'].get.responses[200].schema;
const loadRoomListSchema = Spec.paths['/loadRoomList'].get.responses[200].schema;
const loadPlanSchema = Spec.paths['/loadPlan'].get.responses[200].schema;
const createAccountRequestSchema = Spec.paths['/account'].post.parameters[0].schema;
const createAccountSchema = Spec.paths['/account'].post.responses[200].schema;
const loginRequestSchema = Spec.paths['/login'].post.parameters[0].schema;
const loginSchema = Spec.paths['/login'].post.responses[200].schema;
const loadAccountInfoSchema = Spec.paths['/my-account'].get.responses[200].schema;
const checkSessionSchema = Spec.paths['/member-page-initial'].get.responses[200].schema;
const updateAccountInfoRequestSchema = Spec.paths['/my-account'].put.parameters[0].schema;
const updateAccountInfo = Spec.paths['/my-account'].put.responses[200].schema;
const updateAccountEmailRequestSchema = Spec.paths['/my-account/email'].patch.parameters[0].schema;
const updateAccountEmail = Spec.paths['/my-account/email'].patch.responses[200].schema;
const updateAccountPasswordRequestSchema = Spec.paths['/my-account/password'].patch.parameters[0].schema;
const updateAccountPassword = Spec.paths['/my-account/password'].patch.responses[200].schema;
// TODO: 後々OpenAPI Specificationを渡して型ガードやバリデーションが動作するクライアントを作成して置き換える
export class SwaggerClient {
    server;
    constructor(server) {
        this.server = server instanceof URL ? server : new URL(server);
    }
    async fetch(path, method, body, additionalHeaders) {
        const response = await fetch(new URL(path, this.server), {
            method,
            cache: 'no-store',
            mode: 'cors',
            body: body ? JSON.stringify(body) : undefined,
            headers: {
                'Booking-Engine-Site-Url': location.origin + location.pathname,
                ...(additionalHeaders ?? {}),
            }
        });
        return {
            status: response.status,
            headers: response.headers,
            body: response.status === 204 ? null : await response.json(),
        };
    }
    async publishedInitial() {
        return this.fetch(`/published?path=${location.pathname}`, 'GET');
    }
    async publishedHouseDetail({ house_id }) {
        return this.fetch(`/published/${house_id}?path=${location.pathname}`, 'GET');
    }
    async hostById(host_id) {
        return this.fetch(`/loadHost?host_id=${host_id}`, 'GET');
    }
    async houseById(house_id) {
        return this.fetch(`/loadHouse?house_id=${house_id}`, 'GET');
    }
    async roomListByHouseId(house_id) {
        return this.fetch(`/loadRoomList?house_id=${house_id}`, 'GET');
    }
    async planById(plan_id) {
        return this.fetch(`/loadPlan?plan_id=${plan_id}`, 'GET');
    }
    async pricesOfHouse(props) {
        return this.fetch('/loadPrices', 'POST', props);
    }
    async calenderOfHouse(props) {
        return this.fetch('/loadCalendar', 'POST', props);
    }
    async book(props) {
        return this.fetch('/book', 'POST', props);
    }
    async postTentativeReservation(props) {
        return this.fetch('/tentative-reservation', 'POST', props);
    }
    async retrieveReservation(props) {
        return this.fetch('/retrieve-reservation?' + new URLSearchParams(props), 'GET');
    }
    async verifyOneTimeToken(props) {
        return this.fetch('/verify-one-time-token', 'POST', props);
    }
    async reservationDetail(props) {
        return this.fetch('/loadReservation', 'POST', props);
    }
    async changeSchedule(props) {
        return this.fetch('/changeSchedule', 'POST', props);
    }
    async cancelSchedule(props) {
        return this.fetch('/cancelSchedule', 'POST', props);
    }
    async reservationList(props, headers) {
        return this.fetch('/loadReservationList', 'POST', props, headers);
    }
    async registerNewAccount(props) {
        return this.fetch('/account', 'POST', props);
    }
    async login(props) {
        return this.fetch('/login', 'POST', props);
    }
    async logout() {
        return this.fetch('/logout', 'GET');
    }
    async loadAccountInfo(sessionId) {
        return this.fetch('/my-account', 'GET', undefined, sessionId ? { 'X-Session-Id': sessionId } : undefined);
    }
    async updateAccountInfo(props) {
        return this.fetch('/my-account', 'PUT', props);
    }
    async updateAccountEmail(props) {
        return this.fetch('/my-account/email', 'PATCH', props);
    }
    async updateAccountPassword(props) {
        return this.fetch('/my-account/password', 'PATCH', props);
    }
    async resetPasswordEmail(props) {
        return this.fetch('/reset-password', 'POST', props);
    }
    async resetPassword(props) {
        return this.fetch('/verify-reset-password-token', 'POST', props);
    }
    async deleteAccount() {
        return this.fetch('/my-account', 'DELETE');
    }
    async memberPageInitial() {
        return this.fetch('/member-page-initial', 'GET');
    }
}
;
