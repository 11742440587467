import { UrlAction, InitiatePage, UpdateUrl } from '../Actions/UrlActions';
import { LoadedHouseDetail } from '../Actions/LoadedActions';
export default (dispatch, loadInformation, initialCanonical) => {
    return ({ action, previous, next }) => {
        if (action instanceof InitiatePage) {
            loadInformation.initiatePage(new URL(location.href), dispatch);
        }
        if (action instanceof UpdateUrl) {
            history.pushState(action.query, 'UpdateUrl', action.url);
            const canonical = document.querySelector('link[rel="canonical"]') ?? document.createElement('link');
            canonical.setAttribute('rel', 'canonical');
            canonical.setAttribute('href', action.url.href.slice(action.url.origin.length));
            if (!document.contains(canonical))
                document.head.append(canonical);
        }
        if (action instanceof UrlAction) {
            const hotelId = action.url.searchParams.get('hotel');
            if (hotelId && next.selectedHotel && requireSearch(previous, next)) {
                dispatch(new LoadedHouseDetail('loading'));
                loadInformation.houseDetail(next.selectedHotel, next.from ? new Date(next.from) : null, next.to ? new Date(next.to) : null, dispatch);
            }
        }
    };
};
function requireSearch(previous, next) {
    return previous.selectedHotel !== next.selectedHotel
        ||
            previous.from !== next.from
        ||
            previous.to !== next.to
        ||
            next.searchResult && (!next.searchResult.results
                ||
                    next.searchResult.results instanceof Error);
}
