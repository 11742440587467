import React, { memo } from 'react';
import Shadow from 'react-shadow/emotion';
import generalCss from '../styles/General.sass';
import normalizeCss from '!!raw-loader!normalize.css';
import '!!style-loader!css-loader!sass-loader!../styles/MaterialIconsFont.sass';
function componentsStyle(...styles) {
    const css = styles.join('\n');
    if (!css)
        return null;
    try {
        const styleSheet = new CSSStyleSheet();
        styleSheet.replaceSync(css);
        return styleSheet;
    }
    catch (err) {
        console.warn(err);
        return null;
    }
}
const generalStyles = componentsStyle(generalCss, normalizeCss);
export default function ({ tag, css }, Component) {
    const style = css instanceof Array ? css.join('\n') : css ?? '';
    const styleSheets = [generalStyles, componentsStyle(style)].filter(s => s);
    const Root = Shadow[tag ?? 'div'];
    return memo((props) => (React.createElement(Root, { styleSheets: styleSheets },
        !styleSheets.length
            &&
                React.createElement("style", null,
                    generalCss,
                    normalizeCss,
                    style),
        React.createElement(Component, { ...props }))), sameProps);
}
;
function sameProps(a, b) {
    if (a === b)
        return true;
    if (!(a instanceof Object && b instanceof Object))
        return false;
    if (a.constructor !== b.constructor)
        return false;
    if (a instanceof Function)
        return false;
    if (a instanceof Array) {
        return a.length === b.length
            && a.every((v, i) => sameProps(v, b[i]));
    }
    if (a instanceof Date)
        return a.getTime() === b.getTime();
    return Object.keys({ ...a, ...b }).every(key => {
        if (key === 'children')
            return false;
        return sameProps(a[key], b[key]);
    });
}
