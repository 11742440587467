import React from 'react';
import MaterialIcons from '../styles/MaterialIcons';
import scoped from '../lib/Scoped';
import css from './LoadingIndicator.sass';
/**
 * Primary UI component for user interaction
 */
export default scoped({ css }, ({ children = 'Loading', icon }) => (React.createElement("div", null,
    icon &&
        React.createElement("i", { className: 'icon' }, MaterialIcons[icon]),
    children)));
export const argTypes = {
    children: {
        type: 'string',
        defaultValue: 'Loading'
    },
    icon: {
        control: 'select',
        options: Object.keys(MaterialIcons),
    },
};
