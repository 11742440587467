import { EntityFromSchema, $Schema, $EntityKeys } from '@~/domains/Entities/EntityFromSchema';
import Schema from '../schemas/Plan.yml';
import { Picture } from './Picture';
import { deepCopy } from '../functions';
export class Plan extends EntityFromSchema {
    [$Schema] = Schema;
    [$EntityKeys] = ['plan_id', 'house_id'];
    plan_id;
    house_id;
    display_name;
    cancel_policies;
    recommendation;
    sales_inventory_ids;
    features;
    introduction;
    pictureKeys;
    order_number;
    updated_at;
    constructor(data) {
        super();
        this.plan_id = data.plan_id;
        this.house_id = data.house_id;
        this.display_name = data.display_name ?? '';
        this.cancel_policies = deepCopy(data.cancel_policies) ?? [];
        this.recommendation = deepCopy(data.recommendation) ?? { icon: '', title: '', content: '' };
        this.sales_inventory_ids = deepCopy(data.sales_inventory_ids) ?? [];
        this.features = deepCopy(data.features) ?? [];
        this.introduction = data.introduction ?? '';
        this.pictureKeys = deepCopy(data.pictures) ?? [];
        this.order_number = data.order_number ?? -1;
        this.updated_at = data.updated_at ? new Date(data.updated_at) : new Date();
        this.validate();
    }
    toSerializable() {
        return {
            plan_id: this.plan_id,
            house_id: this.house_id,
            display_name: this.display_name,
            cancel_policies: deepCopy(this.cancel_policies),
            recommendation: { ...this.recommendation },
            sales_inventory_ids: [...this.sales_inventory_ids],
            features: [...this.features],
            introduction: this.introduction,
            pictures: [...this.pictureKeys],
            order_number: this.order_number,
            updated_at: this.updated_at.getTime(),
        };
    }
    equalTo(target) {
        return target.plan_id === this.plan_id;
    }
    get pictures() {
        return this.pictureKeys.map(p => new Picture(p));
    }
}
