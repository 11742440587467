import { addDays, addMonths, format, isSameDay, startOfMonth } from "date-fns";
import { PriceNotDecidable } from "../Exceptions/CalculationExceptions";
import { dateRange, sum } from "../functions";
import { BookingCalculatorInSearch } from "./BookingCalculatorInSearch";
export class BookingCalculatorInOrder {
    plan;
    room;
    priceGroups;
    calendar;
    guestCounts;
    range;
    searchCalculator;
    constructor(plan, room, priceGroups, calendar, guestCounts, range) {
        this.plan = plan;
        this.room = room;
        this.priceGroups = priceGroups;
        this.calendar = calendar;
        this.guestCounts = guestCounts;
        this.range = range;
        this.searchCalculator = new BookingCalculatorInSearch(priceGroups, calendar, guestCounts, range);
    }
    get numberOfNights() {
        return this.searchCalculator.numberOfNights;
    }
    get numberOfAdults() {
        return this.searchCalculator.numberOfAdults;
    }
    get numberOfChildren() {
        return this.searchCalculator.numberOfChildren;
    }
    get enoughVacancy() {
        return this.searchCalculator.enoughVacancyInRange(this.room);
    }
    availableNightsFrom(from) {
        const available = (date) => {
            if (!this.searchCalculator.enoughVacancyInDate(this.room, date))
                return false;
            try {
                return this.searchCalculator.dailyTotal(this.plan, this.room, date) > 0;
            }
            catch (err) {
                if (err instanceof PriceNotDecidable) {
                    return false;
                }
                throw err;
            }
        };
        function stretch(date, num) {
            return available(date) ? stretch(addDays(date, 1), num + 1) : num;
        }
        return stretch(from, 0);
    }
    unitPrice(guestsInRoom, date) {
        try {
            return this.searchCalculator
                .unitPrice(this.plan, this.room, guestsInRoom, date);
        }
        catch (err) {
            if (err instanceof PriceNotDecidable) {
                return 0;
            }
            throw err;
        }
    }
    totalPrice() {
        try {
            return this.searchCalculator.totalPrice(this.plan, this.room);
        }
        catch (err) {
            if (err instanceof PriceNotDecidable) {
                return 0;
            }
            throw err;
        }
    }
    subtotalByRooms(taxPercent) {
        if (!this.range)
            return null;
        const range = dateRange(...this.range);
        return this.guestCounts.map(counts => {
            const subTotal = sum(range.map(date => this.unitPrice(counts, date)));
            const tax = Math.floor((subTotal * taxPercent) / (100 + taxPercent));
            return {
                numberOfAdults: counts.adult,
                numberOfChildren: counts.childA + counts.childB + counts.childC + counts.childD,
                subTotal,
                tax
            };
        });
    }
    pricesInMonth(yearMonth) {
        const firstDay = startOfMonth(new Date(yearMonth));
        const range = dateRange(firstDay, addMonths(firstDay, 1));
        const loaded = this.calendar?.map(c => new Date(c.date)) ?? [];
        if (!range.every(d => loaded.some(l => isSameDay(d, l))))
            return null;
        const dailyTotal = (date) => {
            if (!this.searchCalculator.enoughVacancyInDate(this.room, date))
                return null;
            try {
                return this.searchCalculator.dailyTotal(this.plan, this.room, date);
            }
            catch (err) {
                if (err instanceof PriceNotDecidable) {
                    return null;
                }
                throw err;
            }
        };
        return range.map(date => ({
            date: format(date, 'yyyy-MM-dd'),
            price: dailyTotal(date)
        }));
    }
}
;
