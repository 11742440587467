import { ViewAction } from "@~/components/Actions/ViewAction";
export class ChangeGuestInfo extends ViewAction {
    name;
    nameRuby;
    email;
    phone;
    constructor(guestInfo) {
        super();
        this.name = guestInfo.name;
        this.nameRuby = guestInfo.nameRuby;
        this.email = guestInfo.email;
        this.phone = guestInfo.phone;
    }
}
export class ChangeGuestCounts extends ViewAction {
    guestCounts;
    constructor(guestsCounts) {
        super();
        this.guestCounts = guestsCounts;
    }
}
export class ChangeAnswers extends ViewAction {
    questionsAndAnswers;
    constructor(questionsAndAnswers) {
        super();
        this.questionsAndAnswers = questionsAndAnswers;
    }
}
export class ChangePaymentMethod extends ViewAction {
    paymentMethod;
    constructor(paymentMethod) {
        super();
        this.paymentMethod = paymentMethod;
    }
}
