import { ViewAction } from "@~/components/Actions/ViewAction";
export class ChangeMonth extends ViewAction {
    yearMonth;
    constructor(yearMonth) {
        super();
        this.yearMonth = yearMonth;
    }
}
export class ChangeStaySchedule extends ViewAction {
    checkinDate;
    numberOfNights;
    constructor(checkinDate, numberOfNights) {
        super();
        this.checkinDate = checkinDate;
        this.numberOfNights = numberOfNights;
    }
}
export class SelectStaySchedule extends ViewAction {
    checkinDate;
    numberOfNights;
    constructor(checkinDate, numberOfNights) {
        super();
        this.checkinDate = checkinDate;
        this.numberOfNights = numberOfNights;
    }
}
export class CloseStaySchedule extends ViewAction {
}
