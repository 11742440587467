import React, { lazy, Suspense, useEffect, useRef } from 'react';
import scoped from '../lib/Scoped';
import css from './BookingEngine.sass';
import { ChangeSearchCondition, ExecuteSearch, ChangeResultsOrder, ChangeResultsGroup } from '@~/components/Actions/SearchActions';
import Loading from '../Atoms/LoadingIndicator';
import Widget from '../Organisms/Widget';
import Alert from '../Molecules/Alert';
import { AlertMessage } from '@~/components/Actions/MessageActions';
import { ClosePopup } from '@~/components/Actions/ReserveActions';
import ScreenShadow from '../Atoms/ScreenShadow';
const ResultsLazy = lazy(() => import('../Organisms/SearchResults'));
const PlanDetailLazy = lazy(() => import('../Organisms/PlanDetail'));
const ReservationFlowLazy = lazy(() => import('../Organisms/ReservationFlow'));
const RoomDetailLazy = lazy(() => import('../Organisms/RoomDetail'));
export default scoped({ css }, ({ hotels, selectedHotel, from, to, guestsInRooms, alertMessage, searchResult, reservation, reservationOrder, roomDetail, showIframe, iframe, loginStatus, showMemberSystem, dispatch }) => {
    const widgetRef = useRef(null);
    useEffect(() => {
        if (iframe)
            widgetRef.current?.appendChild(iframe);
    }, [iframe?.src]);
    return (React.createElement("div", { className: 'list' },
        React.createElement("div", { style: { display: showIframe ? 'block' : 'none' }, ref: widgetRef }),
        React.createElement(Widget, { hotels: hotels, selectedHotel: selectedHotel, from: from, to: to, guestsInRooms: guestsInRooms, dispatch: dispatch, loginStatus: loginStatus, showMemberSystem: showMemberSystem }),
        searchResult
            &&
                React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
                    React.createElement(ResultsLazy, { ...searchResult, dispatch: dispatch })),
        (reservation
            ||
                reservationOrder
            ||
                roomDetail)
            &&
                React.createElement(ScreenShadow, { onClick: () => dispatch(new ClosePopup()) }, React.createElement(Suspense, { fallback: React.createElement(Loading, null) }, reservationOrder
                    ? React.createElement(ReservationFlowLazy, { ...reservationOrder, dispatch: dispatch })
                    : roomDetail
                        ? React.createElement(RoomDetailLazy, { ...roomDetail })
                        : reservation
                            ? React.createElement(PlanDetailLazy, { ...reservation, showMemberSystem: showMemberSystem, isLoggedIn: loginStatus.isLoggedIn, dispatch: dispatch })
                            : null)),
        alertMessage
            && React.createElement(Alert, { message: alertMessage, onClose: () => dispatch(new AlertMessage(null)) })));
});
export const parameters = {
    layout: 'fullscreen'
};
export const argTypes = {
    hotels: {
        control: 'object'
    },
    selectedHotel: {
        control: 'number'
    },
    from: {
        control: 'date'
    },
    to: {
        control: 'date'
    },
    guestsInRooms: {
        control: { type: 'object' },
        defaultValue: [
            {
                numberOfAdults: 1,
                numberOfChildren: 0
            }
        ]
    },
    loginStatus: {
        control: { type: 'object' },
        defaultValue: {
            isLoggedIn: false,
        }
    },
    showMemberSystem: {
        control: 'boolean',
        defaultValue: false
    },
    dispatch: {
        type: 'function'
    },
};
export const callbacks = (updateArgs, prev) => {
    return {
        dispatch: (action) => {
            if (action instanceof ExecuteSearch) {
                !prev.searchResult && updateArgs({
                    searchResult: {
                        order: 'recommended',
                        shownGroup: 'rooms',
                        results: 'loading'
                    }
                });
                return;
            }
            if (action instanceof ChangeSearchCondition) {
                updateArgs({
                    selectedHotel: action.selectedHotel,
                    from: action.from?.getTime() ?? undefined,
                    to: action.to?.getTime() ?? undefined,
                    guestsInRooms: action.guestsInRooms
                });
                return;
            }
            if (action instanceof ChangeResultsOrder) {
                updateArgs({
                    searchResult: {
                        ...prev.searchResult,
                        order: action.order
                    }
                });
                return;
            }
            if (action instanceof ChangeResultsGroup) {
                updateArgs({
                    searchResult: {
                        ...prev.searchResult,
                        shownGroup: action.shownGroup
                    }
                });
                return;
            }
            // if(action instanceof TransitionToReservation) {
            //     updateArgs({ planDetail: prev.addedPlanDetail, results: null })
            //     return;
            // }
            // if(action instanceof TransitionToGuestForm) {
            //     updateArgs({ roomBreakdown: prev.addedRoomBreakDown, planDetail: undefined})
            //     return;
            // }
            // if(action instanceof ChangeGuestInfo) {
            //     updateArgs({...{guestName: action.name, guestNameRuby: action.nameRuby, email: action.email, phone: action.phone}});
            //     return;
            // }
            // if (action instanceof ChangeGuestCounts) {
            //     updateArgs({guestCounts: action.guestCounts});
            //     return;
            // }
            // if (action instanceof Reserve) {
            //     updateArgs({completed: true, guestName: action.guest.guestName});
            //     window.scrollTo(0, 0)
            //     return;
            // }
            // if(action instanceof ReturnToReservation) {
            //     updateArgs({
            //         reservation: {
            //             ...prev.reservation!,
            //             introduction: ''
            //         },
            //         reservationOrder: undefined
            //     });
            //     return;
            // }
            // if(action instanceof ChangeMonth) {
            //     updateArgs({
            //         reservation: {
            //             ...prev.reservation!,
            //             yearMonth: action.yearMonth
            //         }
            //     });
            //     return;
            // }
            // if(action instanceof TransitionToRoomDetail) {
            //     updateArgs({
            //         reservation: undefined,
            //         roomDetail: {
            //             ...prev.roomDetail!,
            //             roomDescription: '',
            //         }
            //     })
            //     return;
            // }
        },
    };
};
