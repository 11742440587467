import { ExecuteSearch } from '@~/components/Actions/SearchActions';
import { UpdateUrl } from '@~/web/Applications/Actions/UrlActions';
import { format } from 'date-fns';
import { TransitionToReservation } from '@~/components/Actions/TransitionActions';
import { ClosePopup } from '@~/components/Actions/ReserveActions';
export default (dispatch) => ({ action, previous, next }) => {
    if (action instanceof ExecuteSearch) {
        if (previous.searchResult?.results === 'loading')
            return;
        const url = new URL(location.search, previous.site?.baseUrl ?? location.href);
        [
            ['hotel', previous.selectedHotel],
            ['from', dateFormat(previous.from)],
            ['to', dateFormat(previous.to)],
            ['guests', JSON.stringify(previous.guestsInRooms)],
            ['plan', null],
            ['room', null],
        ].forEach(([key, value]) => {
            value
                ? url.searchParams.set(key, String(value))
                : url.searchParams.delete(key);
        });
        dispatch(new UpdateUrl(url));
    }
    if (action instanceof TransitionToReservation) {
        const url = new URL(location.search, previous.site?.baseUrl);
        [
            ['plan', action.planId],
            ['room', action.roomTypeId],
        ].forEach(([key, value]) => {
            value
                ? url.searchParams.set(key, String(value))
                : url.searchParams.delete(key);
        });
        dispatch(new UpdateUrl(url));
    }
    if (action instanceof ClosePopup) {
        if (!previous.reservationOrder || previous.reservationOrder.status === 'complete') {
            const url = new URL(location.search, previous.site?.baseUrl);
            ['plan', 'room'].forEach(key => url.searchParams.delete(key));
            dispatch(new UpdateUrl(url));
        }
    }
};
function dateFormat(source) {
    return source ? format(new Date(source), 'yyyy-MM-dd') : null;
}
