import React from 'react';
import scoped from '../lib/Scoped';
import css from './Select.sass';
export default scoped({ css }, ({ onChange, placeholder = '-', isClearable, options, selected }) => {
    const optionsArray = Object.entries(options).map(([value, label]) => ({ value, label }));
    const listener = (option) => {
        if (!option)
            return onChange(null);
        const num = Number(option.value);
        const key = (Number.isInteger(num)
            ? num
            : option.value);
        onChange(key);
    };
    return (React.createElement("select", { className: 'select', onChange: e => listener(e.target.value ? { value: e.target.value, label: e.target.value } : null), value: selected || '' },
        isClearable && React.createElement("option", { value: "" }, "-"),
        optionsArray.map(({ value, label }) => React.createElement("option", { key: value, value: value }, label))));
});
export const argTypes = {
    onChange: {
        type: 'function'
    },
    placeholder: {
        type: 'string'
    },
    isClearable: {
        type: 'boolean'
    },
    options: {
        control: 'object',
        defaultValue: {
            1: 'AAAA',
            2: 'BBBB'
        }
    },
    selected: {
        type: 'number'
    },
};
export const callbacks = (updateArgs) => ({
    onChange: (num) => updateArgs({ selected: num })
});
