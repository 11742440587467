import { PriceGroup } from '@~/domains/Entities/PriceGroup';
import { DateState } from '@~/domains/Entities/DateState';
import { format } from 'date-fns';
export class BookingRepository {
    loader;
    constructor(loader) {
        this.loader = loader;
    }
    async *priceGroupsOfHouse(house) {
        for await (const raw of this.loader.pricesOfHouse(house.house_id)) {
            yield new PriceGroup(raw);
        }
    }
    async *dateStateOfHouse(house, first, last) {
        for await (const raw of this.loader.calenderOfHouse(house.house_id, format(first, 'yyyy-MM-dd'), format(last, 'yyyy-MM-dd'))) {
            yield new DateState(raw);
        }
    }
}
