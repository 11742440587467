import { UrlAction } from '../../Applications/Actions/UrlActions';
import { displayPlan, displayRoom } from './utility';
import { format } from 'date-fns';
import { BookingCalculatorInOrder } from '@~/domains/Services/BookingCalculatorInOrder';
import { SetupIframe } from '../Actions/IframeActions';
export default (pictureToUrl) => {
    return (action, previous) => {
        if (action instanceof UrlAction) {
            return {
                ...previous,
                ...queryToProps(previous, action.query, previous.host?.showMemberSystem ?? false)
            };
        }
        if (action instanceof SetupIframe) {
            if (previous.iframe?.src === action.iframe.src)
                return previous;
            return {
                ...previous,
                iframe: action.iframe
            };
        }
        return previous;
    };
    function queryToProps(previous, query, showMemberSystem) {
        const selectedHotel = previous.hotels
            ? (query.hotel && (query.hotel in previous.hotels)
                ? Number(query.hotel)
                : null)
            : Number(query.hotel) || previous.selectedHotel || null;
        const from = query.from || undefined;
        const to = query.to || undefined;
        const guestsInRooms = query.guests
            ? JSON.parse(query.guests)
            : [{ numberOfChildren: 0, numberOfAdults: 1 }];
        const planId = Number(query.plan) || null;
        const plan = previous.loaded?.plans.find(p => p.plan_id === planId);
        const roomId = Number(query.room) || null;
        const room = previous.loaded?.rooms.find(r => r.sales_inventory_id === roomId);
        const yearMonth = format(from ? new Date(from) : new Date(), 'yyyy-MM');
        const searchResult = query.hotel ? previous.searchResult : undefined;
        const priceGroups = previous.loaded?.prices;
        const calendar = previous.loaded?.calendar ?? null;
        const guestCounts = previous.reservationOrder?.guestCounts
            ?? guestsInRooms.map(({ numberOfAdults: adult, numberOfChildren: childA }) => ({ adult, childA, childB: 0, childC: 0, childD: 0 }));
        const range = previous.from && previous.to
            ? [new Date(previous.from), new Date(previous.to)]
            : null;
        const reservation = (() => {
            if (plan && room && priceGroups) {
                const calc = new BookingCalculatorInOrder(plan, room, priceGroups, calendar, guestCounts, range);
                return {
                    plan: displayPlan(plan, pictureToUrl),
                    room: displayRoom(room, pictureToUrl),
                    numberOfNights: calc.numberOfNights,
                    numberOfAdults: calc.numberOfAdults,
                    numberOfChildren: calc.numberOfChildren,
                    specialPrice: calc.totalPrice(),
                    regularPrice: null,
                    yearMonth,
                    priceList: calc.pricesInMonth(yearMonth),
                    scheduleSelect: null,
                    showCalendarModal: false,
                };
            }
            return undefined;
        })();
        return {
            selectedHotel,
            from,
            to,
            guestsInRooms,
            searchResult,
            reservation: reservation ? { ...reservation } : undefined,
        };
    }
};
