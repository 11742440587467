export class TentativeReservationRepository {
    loader;
    constructor(loader) {
        this.loader = loader;
    }
    async tradingInformation(trading_id, hash_code) {
        const body = await this.loader.tentativeReservation(trading_id, hash_code);
        return body;
    }
}
