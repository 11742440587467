import { House } from '@~/domains/Entities/House';
import { NotAllowedModification } from '../Exceptions/NotAllowedModification';
export class HousesRepository {
    loader;
    constructor(loader) {
        this.loader = loader;
    }
    async *listOfHost(host_id) {
        for await (const raw of this.loader.houses()) {
            if (raw.host_id !== host_id)
                continue;
            yield new House(raw);
        }
    }
    async save(house) {
        throw new NotAllowedModification();
    }
    async load(house_id) {
        for await (const raw of this.loader.houses()) {
            if (raw.house_id === house_id) {
                return new House(raw);
            }
        }
        return null;
    }
    async remove(house) {
        throw new NotAllowedModification();
    }
}
