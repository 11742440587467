import { Room } from '@~/domains/Entities/Room';
import { House } from '@~/domains/Entities/House';
import { NotAllowedModification } from '../Exceptions/NotAllowedModification';
export class RoomsRepository {
    loader;
    constructor(loader) {
        this.loader = loader;
    }
    async load(sales_inventory_id) {
        for await (const h of this.loader.houses()) {
            for await (const room of this.listOfHouse(new House(h))) {
                if (room.sales_inventory_id === sales_inventory_id)
                    return room;
            }
        }
        return null;
    }
    async *listOfHouse(house) {
        for await (const p of this.loader.roomsOfHouse(house.house_id)) {
            yield new Room(p);
        }
    }
    async save(room) {
        throw new NotAllowedModification();
    }
    async remove(room) {
        throw new NotAllowedModification();
    }
}
