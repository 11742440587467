export default class CacheContainer {
    cache = {};
    async through(key, loader) {
        if (this.cache[key])
            return this.cache[key];
        try {
            this.cache[key] = loader();
            return await this.cache[key];
        }
        catch (error) {
            delete this.cache[key];
            throw error;
        }
    }
}
