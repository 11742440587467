import { FailureResponse } from "@~/web/Applications/Exceptions/FailureResponse";
import { ReservationConflicted } from "@~/web/Applications/Exceptions/ReservationConflicted";
export class ReservationSender {
    api;
    constructor(api) {
        this.api = api;
    }
    async book(props) {
        const response = await this.api.book(props);
        if (response.status === 200) {
            return response.body;
        }
        if (response.status === 409) {
            throw new ReservationConflicted(response.body.message, response.body.code);
        }
        throw new FailureResponse(response.body.message, response.body.code);
    }
    async changeSchedule(props) {
        const response = await this.api.changeSchedule(props);
        if (response.status === 200) {
            return;
        }
        if (response.status === 409) {
            throw new ReservationConflicted(response.body.message, response.body.code);
        }
        throw new FailureResponse(response.body.message, response.body.code);
    }
    async cancelSchedule(props) {
        const response = await this.api.cancelSchedule(props);
        if (response.status === 200) {
            return;
        }
        throw new FailureResponse(response.body.message, response.body.code);
    }
}
