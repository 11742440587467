import React from 'react';
import MaterialIcons from '../styles/MaterialIcons';
import scoped from '../lib/Scoped';
import css from './Button.sass';
export default scoped({ css }, ({ label, type = 'button', beforeIcon, afterIcon, skeleton, fullWidth, onClick }) => {
    const background = skeleton ? 'skeleton' : 'primary';
    const className = fullWidth ? `full ${background}` : background;
    return (React.createElement("button", { className: className, type: type, onClick: () => onClick() },
        beforeIcon
            &&
                React.createElement("i", { className: 'before-icon' }, MaterialIcons[beforeIcon]),
        label,
        afterIcon
            &&
                React.createElement("i", { className: 'after-icon' }, MaterialIcons[afterIcon])));
});
export const argTypes = {
    label: {
        type: 'string',
        defaultValue: 'Button'
    },
    beforeIcon: {
        control: 'select',
        options: Object.keys(MaterialIcons),
    },
    afterIcon: {
        control: 'select',
        options: Object.keys(MaterialIcons),
    },
    onClick: {
        type: 'function'
    },
    skeleton: {
        type: 'boolean'
    },
    fullWidth: {
        type: 'boolean'
    }
};
export const callbacks = () => ({
    onClick: () => null
});
