import { asyncIterate } from '@~/domains/functions';
import { LoadFailed, LoadedHouses, LoadedHouseDetail, LoadedCalender, CatchOneTimeToken, LoadedSite, LoadedHost } from '@~/web/Applications/Actions/LoadedActions';
import { HostsRepository } from '../Repositories/HostsRepository';
import { HousesRepository } from '../Repositories/HousesRepository';
import { PlansRepository } from '../Repositories/PlansRepository';
import { RoomsRepository } from '../Repositories/RoomsRepository';
import { SitesRepository } from '../Repositories/SitesRepository';
import { BookingRepository } from '../Repositories/BookingRepository';
import { AlertMessage } from '@~/components/Actions/MessageActions';
export class LoadInformation {
    hosts;
    sites;
    houses;
    rooms;
    plans;
    booking;
    static initWithLoader(loader) {
        return new this(new HostsRepository(loader), new SitesRepository(loader), new HousesRepository(loader), new RoomsRepository(loader), new PlansRepository(loader), new BookingRepository(loader));
    }
    constructor(hosts, sites, houses, rooms, plans, booking) {
        this.hosts = hosts;
        this.sites = sites;
        this.houses = houses;
        this.rooms = rooms;
        this.plans = plans;
        this.booking = booking;
    }
    async initiatePage(url, dispatch) {
        try {
            const ott = this.takeOneTimeToken();
            if (ott)
                dispatch(new CatchOneTimeToken(ott));
            const site = await this.sites.mostMatched(url);
            if (!site)
                throw new LoadFailed('サイト情報の読み込みに失敗しました');
            dispatch(new LoadedSite(site));
            const houses = await asyncIterate(this.houses.listOfHost(site.host_id));
            if (!houses.length)
                throw new LoadFailed('施設情報の読み込みに失敗しました');
            dispatch(new LoadedHouses(houses));
            const host = await this.hosts.load();
            if (!host)
                throw new LoadFailed('ホスト情報の読み込みに失敗しました');
            dispatch(new LoadedHost(host));
        }
        catch (error) {
            console.error(error);
            const data = error instanceof LoadFailed
                ? error
                : new LoadFailed('施設情報の読み込みに失敗しました');
            dispatch(new LoadedHouses(data));
        }
    }
    takeOneTimeToken() {
        const url = new URL(location.href);
        const token = url.searchParams.get('ott');
        if (!token)
            return null;
        url.searchParams.delete('ott');
        history.replaceState(history.state, '', url);
        return token;
    }
    async houseDetail(house_id, first, last, dispatch) {
        try {
            const house = await this.houses.load(house_id);
            if (!house)
                throw new LoadFailed('施設情報の読み込みに失敗しました');
            const [rooms, plans, prices, calendar,] = await Promise.all([
                asyncIterate(this.rooms.listOfHouse(house)),
                asyncIterate(this.plans.listOfHouse(house)),
                asyncIterate(this.booking.priceGroupsOfHouse(house)),
                (first && last)
                    ? asyncIterate(this.booking.dateStateOfHouse(house, first, last))
                    : null,
            ]);
            dispatch(new LoadedHouseDetail({ house, rooms, plans, prices, calendar }));
        }
        catch (error) {
            console.error(error);
            const data = error instanceof LoadFailed
                ? error
                : new LoadFailed('施設情報の読み込みに失敗しました');
            dispatch(new LoadedHouseDetail(data));
        }
    }
    async priceCalender(house_id, first, last, dispatch) {
        try {
            const house = await this.houses.load(house_id);
            if (!house)
                throw new LoadFailed('料金カレンダーの読み込みに失敗しました');
            const calendar = await asyncIterate(this.booking.dateStateOfHouse(house, first, last));
            dispatch(new LoadedCalender(calendar));
        }
        catch (error) {
            console.error(error);
            const message = error instanceof LoadFailed
                ? error.message
                : '料金カレンダーの読み込みに失敗しました';
            dispatch(new AlertMessage(message));
        }
    }
}
