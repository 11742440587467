import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
export default class {
    element;
    component;
    store;
    dispatch;
    root;
    constructor(element, component, store, dispatch) {
        this.element = element;
        this.component = component;
        this.store = store;
        this.dispatch = dispatch;
        this.root = createRoot(element);
        this.store.observedBy(() => this.render());
        this.render();
    }
    async appendNextTo(sibling) {
        if (!sibling.parentElement)
            return;
        sibling.parentElement.insertBefore(this.element, sibling.nextSibling);
        this.render();
    }
    async appendTo(parent) {
        parent.appendChild(this.element);
        this.render();
    }
    render() {
        const document = this.element.ownerDocument;
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', () => this.render(), { once: true });
            return;
        }
        if (!document.body.contains(this.element))
            return;
        this.root.render(createElement(this.component, {
            ...this.store.getState(),
            dispatch: this.dispatch
        }));
    }
}
