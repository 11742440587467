import React, { useState } from 'react';
import scoped from '../lib/Scoped';
import css from './GuestsNumberInput.sass';
import SelectNumber from './SelectNumber';
import DropDown from '../Atoms/DropDown';
import Button from '../Atoms/Button';
import MaterialIcons from '../styles/MaterialIcons';
const GuestsNumberInput = scoped({ css }, ({ guestsInRooms, onChange, onClose }) => {
    const onChangeAdultCount = (index, num) => onChange(guestsInRooms.map((r, i) => i === index ? { ...r, numberOfAdults: num } : r));
    const onChangeChildCount = (index, num) => onChange(guestsInRooms.map((r, i) => i === index ? { ...r, numberOfChildren: num } : r));
    const onRemove = (index) => onChange(guestsInRooms.filter((_, i) => i !== index));
    const onAdd = () => onChange([...guestsInRooms, { numberOfAdults: 1, numberOfChildren: 0 }]);
    return (React.createElement(React.Fragment, null,
        React.createElement("dl", null, guestsInRooms.map((room, index) => (React.createElement("div", { className: 'room-wrapper', key: index },
            React.createElement("dt", null,
                "\u90E8\u5C4B",
                index + 1),
            React.createElement("dd", null,
                React.createElement("label", { id: "adult-count" },
                    React.createElement("span", { className: 'number-label' }, "\u5927\u4EBA"),
                    React.createElement(SelectNumber, { selected: room.numberOfAdults, onChange: (input) => onChangeAdultCount(index, input ?? 1), max: 12 })),
                React.createElement("label", { id: "children-count" },
                    React.createElement("span", { className: 'number-label' }, "\u5B50\u4F9B"),
                    React.createElement(SelectNumber, { selected: room.numberOfChildren, min: 0, onChange: (input) => onChangeChildCount(index, input ?? 0), max: 12 })),
                guestsInRooms.length > 1
                    &&
                        React.createElement("span", { className: "delete-button", onClick: () => onRemove(index) }, MaterialIcons.clear)))))),
        React.createElement("div", { className: 'add-button-wrapper' },
            React.createElement(Button, { label: '\u8FFD\u52A0\u3059\u308B', beforeIcon: 'add', onClick: onAdd, skeleton: true })),
        React.createElement("div", { className: 'close-button-wrapper' },
            React.createElement("button", { onClick: onClose }, "\u8A2D\u5B9A\u3059\u308B"))));
});
export default (props) => {
    const [isOpen, flipDropDown] = useState(false);
    const sumOfAdults = props.guestsInRooms.reduce((sum, room) => sum + room.numberOfAdults, 0);
    const sumOfChildren = props.guestsInRooms.reduce((sum, room) => sum + room.numberOfChildren, 0);
    const numberOfRooms = props.guestsInRooms.length;
    const display = sumOfChildren
        ? `大人${sumOfAdults}人、子供${sumOfChildren}人、${numberOfRooms}部屋`
        : `大人${sumOfAdults}人、${numberOfRooms}部屋`;
    return (React.createElement(DropDown, { isOpen: isOpen, display: display, onFlip: op => flipDropDown(op) },
        React.createElement(GuestsNumberInput, { ...props, onClose: () => flipDropDown(false) })));
};
export const argTypes = {
    guestsInRooms: {
        control: { type: 'object' },
        defaultValue: [
            {
                numberOfAdults: 1,
                numberOfChildren: 0
            }
        ]
    },
    onChange: {
        type: 'function',
    },
};
export const callbacks = (updateArgs, prev) => ({
    onChange: guestsInRooms => updateArgs({ guestsInRooms }),
});
