import { ViewAction } from "@~/components/Actions/ViewAction";
export class ChangeSearchCondition extends ViewAction {
    selectedHotel;
    from;
    to;
    guestsInRooms;
    constructor(selectedHotel, from, to, guestsInRooms) {
        super();
        this.selectedHotel = selectedHotel;
        this.from = from;
        this.to = to;
        this.guestsInRooms = guestsInRooms;
    }
}
const TypeGuardExec = Symbol();
export class ExecuteSearch extends ViewAction {
    [TypeGuardExec];
}
export class ChangeResultsOrder extends ViewAction {
    order;
    constructor(order) {
        super();
        this.order = order;
    }
}
export class ChangeResultsGroup extends ViewAction {
    shownGroup;
    constructor(shownGroup) {
        super();
        this.shownGroup = shownGroup;
    }
}
