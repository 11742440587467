import { addDays, startOfDay } from "date-fns";
export function sameWhole(a, b) {
    if (a === b)
        return true;
    if (!(a instanceof Object && b instanceof Object))
        return false;
    if (a.constructor !== b.constructor)
        return false;
    if (a instanceof Function) {
        return a.toString() === b.toString();
    }
    if (a instanceof Array) {
        return a.length === b.length
            && a.every((v, i) => sameWhole(v, b[i]));
    }
    return Object.keys({ ...a, ...b }).every(key => sameWhole(a[key], b[key]));
}
export function deepCopy(src) {
    if (src instanceof Array)
        return src.map(s => deepCopy(s));
    if (src instanceof Object) {
        const entries = Object.entries(src).map(([key, value]) => [key, deepCopy(value)]);
        return Object.fromEntries(entries);
    }
    return src;
    ;
}
export function mapObject(src, callback) {
    const entries = Object.entries(src).map(([key, value]) => ([key, callback(value, key)]));
    return Object.fromEntries(entries);
}
export function filterObject(src, callback) {
    const entries = Object.entries(src).filter(([key, value]) => callback(value, key));
    return Object.fromEntries(entries);
}
export async function asyncIterate(iterable) {
    const result = [];
    for await (const v of iterable)
        result.push(v);
    return result;
}
export function dateRange(begin, end) {
    function* iterateDate() {
        for (let date = startOfDay(begin); date < startOfDay(end); date = addDays(date, 1)) {
            yield date;
        }
    }
    return [...iterateDate()];
}
export function sum(numbers) {
    return numbers.reduce((t, c) => t + c, 0);
}
