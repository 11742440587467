import { EntityFromSchema, $Schema, $EntityKeys } from '@~/domains/Entities/EntityFromSchema';
import { deepCopy } from '../functions';
import Schema from '../schemas/Site.yml';
export class Site extends EntityFromSchema {
    [$Schema] = Schema;
    [$EntityKeys] = ['base_url', 'host_id'];
    baseUrl;
    host_id;
    house_ids;
    memberPageUrl;
    constructor(data) {
        super();
        this.baseUrl = new URL(data.base_url ?? data.origin);
        this.host_id = data.host_id;
        this.house_ids = deepCopy(data.house_ids) ?? [];
        this.memberPageUrl = new URL(data.member_page_url ?? this.baseUrl.href);
        this.validate();
    }
    get origin() {
        return this.baseUrl.origin;
    }
    get basePath() {
        return this.baseUrl.pathname;
    }
    get base_url() {
        return this.baseUrl.href;
    }
    get member_page_url() {
        return this.memberPageUrl.href;
    }
    toSerializable() {
        return {
            base_url: this.base_url,
            origin: this.origin,
            host_id: this.host_id,
            house_ids: [...this.house_ids],
            member_page_url: this.member_page_url
        };
    }
    equalTo(target) {
        return target.base_url === this.base_url;
    }
}
