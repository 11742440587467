import { LoadInformation } from '../Services/LoadInformation';
import { PublishedDataFetcher } from '@~/web/Adapters/ApiClient/PublishedDataFetcher';
import urlObserver from './urlObserver';
import searchObserver from './searchObserver';
import loadedObserver from './loadedObserver';
import iframeObserver from './iframeObserver';
import registerReservationObserver from './registerReservationObserver';
import { ReservationSender } from '@~/web/Adapters/ApiClient/ReservationSender';
function mergeObservers(...observers) {
    return (reduced) => observers.forEach(ob => ob(reduced));
}
;
export default (dispatcher, api, initialCanonical) => {
    const loader = new PublishedDataFetcher(api);
    const loadInfoService = LoadInformation.initWithLoader(loader);
    const sender = new ReservationSender(api);
    return mergeObservers(loadedObserver(dispatcher.dispatch), urlObserver(dispatcher.dispatch, loadInfoService, initialCanonical), searchObserver(dispatcher.dispatch), registerReservationObserver(dispatcher.dispatch, sender, loadInfoService), iframeObserver(dispatcher.dispatch));
};
