import React from 'react';
import scoped from '../lib/Scoped';
import css from './SelectNumber.sass';
function extract(input) {
    const substr = input.match(/([0-9０-９])+/)?.[0];
    const singleBytes = substr?.replace(/[０-９]/g, char => String.fromCharCode(char.charCodeAt(0) - 0xFEE0));
    if (singleBytes === undefined)
        return null;
    const parsed = Number(singleBytes);
    return isNaN(parsed) ? null : parsed;
}
;
function toLabel(num, unit, placeholder = '-') {
    return num === null ? placeholder : `${num}${unit || ''}`;
}
;
function toOption(num, unit, placeholder = '-') {
    const value = typeof num === 'string' ? extract(num) : num;
    const label = toLabel(value, unit, placeholder);
    return { value, label };
}
;
export default scoped({ css }, ({ onChange, placeholder = '-', unit, isClearable, step = 1, min = 1, max = 20, selected }) => {
    const numbers = [...(function* () { if (step > 0)
            for (let i = min; i <= max; i += step)
                yield i; })()].map(num => toOption(num, unit));
    const listener = (value) => {
        const num = typeof value === 'string' ? extract(value) : value ?? null;
        onChange?.(num);
    };
    return (React.createElement("select", { onChange: (el) => listener(el.target.value), value: String(selected) },
        selected === null ? React.createElement("option", { value: "" }, placeholder) : null,
        numbers.map(({ value, label }) => React.createElement("option", { key: value, value: String(value) }, label))));
});
export const argTypes = {
    onChange: {
        type: 'function'
    },
    placeholder: {
        type: 'string'
    },
    unit: {
        type: 'string'
    },
    isClearable: {
        type: 'boolean'
    },
    step: {
        type: 'number',
        defaultValue: 1
    },
    min: {
        type: 'number'
    },
    max: {
        type: 'number'
    },
    selected: {
        type: 'number'
    },
};
export const callbacks = (updateArgs) => ({
    onChange: (num) => updateArgs({ selected: num })
});
