import { Site } from '@~/domains/Entities/Site';
import { IdentifierNotMatched } from '../Exceptions/IdentifierNotMatched';
import { NotAllowedModification } from '../Exceptions/NotAllowedModification';
export class SitesRepository {
    loader;
    constructor(loader) {
        this.loader = loader;
    }
    async loadFixed(baseUrl) {
        const raw = await this.loader.site();
        if (baseUrl.href !== raw.base_url)
            throw new IdentifierNotMatched();
        return new Site(raw);
    }
    async mostMatched(url) {
        const raw = await this.loader.site();
        if (!url.href.startsWith(raw.base_url))
            throw new IdentifierNotMatched();
        return new Site(raw);
    }
    async loadByMemberPageUrl(origin) {
        const raw = await this.loader.site();
        if (raw.member_page_url !== origin)
            throw new IdentifierNotMatched();
        return new Site(raw);
    }
    async *listOfHost(host_id) {
        const raw = await this.loader.site();
        if (raw.host_id === host_id) {
            yield new Site(raw);
        }
    }
    async save(site) {
        throw new NotAllowedModification();
    }
    async remove(site) {
        throw new NotAllowedModification();
    }
}
