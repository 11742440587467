export class DomainException extends Error {
}
export class InvalidDataType extends DomainException {
}
export class InvalidModification extends DomainException {
    classObject;
    columns;
    constructor(classObject, columns) {
        const message = `Columns cannot changes.: ${classObject.name} #[${columns.join(', ')}]`;
        super(message);
        this.classObject = classObject;
        this.columns = columns;
    }
}
export class EntityNotFound extends DomainException {
    classObject;
    keys;
    constructor(classObject, ...keys) {
        const message = `Not found.: ${classObject.name} #[${keys.join(', ')}]`;
        super(message);
        this.classObject = classObject;
        this.keys = keys;
    }
}
export class InvalidAssociation extends DomainException {
    sourceClass;
    sourceId;
    distinationClass;
    distinationId;
    constructor(sourceClass, sourceId, distinationClass, distinationId) {
        const src = sourceClass instanceof Object ? sourceClass.name : sourceClass;
        const dest = distinationClass instanceof Object ? distinationClass.name : distinationClass;
        const message = `Invalid association.: ${src}.${sourceId} => ${dest}.${distinationId}`;
        super(message);
        this.sourceClass = sourceClass;
        this.sourceId = sourceId;
        this.distinationClass = distinationClass;
        this.distinationId = distinationId;
    }
}
export class ValidationFailed extends DomainException {
    source;
    constructor(source) {
        const message = source.details.map(e => `[${e.property}] ${e.message}`).join('\n');
        super(message);
        this.source = source;
    }
}
