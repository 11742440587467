import { EntityFromSchema, $Schema, $EntityKeys } from '@~/domains/Entities/EntityFromSchema';
import Schema from '../schemas/Host.yml';
export class Host extends EntityFromSchema {
    [$Schema] = Schema;
    [$EntityKeys] = ['host_id'];
    host_id;
    display_name;
    memberPageUrl;
    showMemberSystem = true;
    notification_email;
    payment_system = null;
    constructor(data) {
        super();
        this.host_id = data.host_id;
        this.display_name = data.display_name ?? '';
        this.memberPageUrl = new URL(data.member_page_url);
        this.showMemberSystem = data.showMemberSystem ?? false;
        this.notification_email = data.notification_email ?? '';
        this.payment_system = data.payment_system === 'kessaikun' ? data.payment_system : null;
        this.validate();
    }
    get member_page_url() {
        return this.memberPageUrl.href;
    }
    toSerializable() {
        return {
            host_id: this.host_id,
            display_name: this.display_name,
            member_page_url: this.member_page_url,
            showMemberSystem: this.showMemberSystem,
            notification_email: this.notification_email,
            payment_system: this.payment_system ?? '',
        };
    }
    equalTo(target) {
        return target.host_id === this.host_id;
    }
}
