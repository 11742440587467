import { validateMatchToSchema, NotMatchToSchema } from 'openapi-validator';
import { ValidationFailed, InvalidModification } from '../Exceptions/DomainException';
import { sameWhole } from '../functions';
export const $Schema = Symbol('$Schema');
export const $EntityKeys = Symbol('$EntityKeys');
export class EntityFromSchema {
    toJSON() {
        return this.toSerializable();
    }
    toString() {
        const data = this.toSerializable();
        const columns = this[$EntityKeys];
        const keys = columns.map(k => String(data[k])).join(', ');
        return `[${this.constructor.name} # ${keys}]`;
    }
    patch(updates) {
        const previous = this.toSerializable();
        const updated = {
            ...previous,
            ...updates
        };
        const columns = this[$EntityKeys];
        const invalidChanges = columns.filter(col => !sameWhole(previous[col], updated[col]));
        if (invalidChanges.length) {
            throw new InvalidModification(this.constructor, invalidChanges);
        }
        validate(this[$Schema], updated);
        return new this.constructor(updated);
    }
    validate() {
        const self = this.toSerializable();
        validate(this[$Schema], self);
    }
}
export function validate(schema, instance) {
    try {
        validateMatchToSchema(schema, instance);
    }
    catch (error) {
        if (error instanceof NotMatchToSchema) {
            throw new ValidationFailed(error);
        }
        throw error;
    }
}
