import { Plan } from '@~/domains/Entities/Plan';
import { House } from '@~/domains/Entities/House';
import { NotAllowedModification } from '../Exceptions/NotAllowedModification';
export class PlansRepository {
    loader;
    constructor(loader) {
        this.loader = loader;
    }
    async load(plan_id) {
        for await (const h of this.loader.houses()) {
            for await (const plan of this.listOfHouse(new House(h))) {
                if (plan.plan_id === plan_id)
                    return plan;
            }
        }
        return null;
    }
    async *listOfHouse(house) {
        for await (const p of this.loader.plansOfHouse(house.house_id)) {
            yield new Plan(p);
        }
    }
    async save(plan) {
        throw new NotAllowedModification();
    }
    async remove(plan) {
        throw new NotAllowedModification();
    }
}
