import { ReturnToReservation, TransitionToGuestForm } from '@~/components/Actions/TransitionActions';
import { Reserve } from '@~/components/Actions/ReserveActions';
import { ChangeAnswers, ChangeGuestCounts, ChangeGuestInfo, ChangePaymentMethod } from '@~/components/Actions/ChangeGuestFormActions';
import { CompleteReserve, ErrorReserve } from '../Actions/ReserveActions';
import { toSingleByte } from "./utility";
import { BookingCalculatorInOrder } from '@~/web/@Components/@Domains/Services/BookingCalculatorInOrder';
import { format } from 'date-fns';
export default (taxPercent) => {
    return (action, previous) => {
        if (action instanceof TransitionToGuestForm) {
            if (!previous.loaded || !previous.reservation)
                return previous;
            if (!previous.from || !previous.to) {
                return {
                    ...previous,
                    reservation: {
                        ...previous.reservation,
                        showCalendarModal: true
                    }
                };
            }
            const guestTypeDisplayName = Object.fromEntries(previous.loaded.house.child_categories.map(category => [
                category.key,
                category.display_name,
            ]));
            const guestCounts = previous.guestsInRooms.map(counts => ({
                adult: counts.numberOfAdults,
                childA: counts.numberOfChildren,
                childB: 0,
                childC: 0,
                childD: 0,
            }));
            const questionsAndAnswers = previous.loaded.house.questions.map(question => ({ question, answer: '' }));
            const calculator = calculatorFromState(previous);
            const checkin = typeof previous.from === 'number' ? format(new Date(previous.from), "yyyy-MM-dd") : previous.from;
            const checkout = typeof previous.to === 'number' ? format(new Date(previous.to), "yyyy-MM-dd") : previous.to;
            return {
                ...previous,
                reservationOrder: {
                    planId: previous.reservation.plan.id,
                    roomTypeId: previous.reservation.room.id,
                    guestInfo: action.guestInfo ?? {
                        email: '',
                        name: '',
                        nameRuby: '',
                        phone: '',
                    },
                    guestTypeDisplayName,
                    guestCounts,
                    questionsAndAnswers,
                    status: 'input',
                    paymentMethod: 'onSite',
                    privacyPolicyUrl: previous.loaded.house.privacy_policy_url,
                    roomTypeName: previous.reservation.room.displayName,
                    planName: previous.reservation.plan.displayName,
                    checkin,
                    checkout,
                    canPayInAdvance: previous.host?.payment_system === 'kessaikun',
                    roomBreakdown: calculator?.subtotalByRooms(taxPercent) ?? [],
                    memberId: action.memberId
                }
            };
        }
        if (action instanceof ChangeGuestInfo) {
            if (!previous.reservationOrder)
                return previous;
            return {
                ...previous,
                reservationOrder: {
                    ...previous.reservationOrder,
                    guestInfo: {
                        name: action.name,
                        nameRuby: action.nameRuby,
                        phone: toSingleByte(action.phone).replace(/[^0-9]/g, ''),
                        email: toSingleByte(action.email),
                    }
                }
            };
        }
        if (action instanceof ChangeGuestCounts) {
            if (!previous.reservationOrder)
                return previous;
            const calculator = calculatorFromState(previous, { guestCounts: action.guestCounts });
            return {
                ...previous,
                reservationOrder: {
                    ...previous.reservationOrder,
                    guestCounts: action.guestCounts,
                    roomBreakdown: calculator?.subtotalByRooms(taxPercent) ?? [],
                }
            };
        }
        if (action instanceof ChangeAnswers) {
            if (!previous.reservationOrder)
                return previous;
            return {
                ...previous,
                reservationOrder: {
                    ...previous.reservationOrder,
                    questionsAndAnswers: action.questionsAndAnswers,
                }
            };
        }
        if (action instanceof ChangePaymentMethod) {
            if (!previous.reservationOrder)
                return previous;
            if (previous.host?.payment_system !== 'kessaikun')
                return previous;
            return {
                ...previous,
                reservationOrder: {
                    ...previous.reservationOrder,
                    paymentMethod: action.paymentMethod,
                }
            };
        }
        if (action instanceof ReturnToReservation) {
            return {
                ...previous,
                reservationOrder: undefined
            };
        }
        if (action instanceof Reserve) {
            if (previous.reservationOrder?.status !== 'input')
                return previous;
            return {
                ...previous,
                reservationOrder: {
                    ...previous.reservationOrder,
                    status: 'sending'
                }
            };
        }
        if (action instanceof CompleteReserve) {
            if (!previous.reservationOrder)
                return previous;
            return {
                ...previous,
                tradingId: undefined,
                reservationOrder: {
                    ...previous.reservationOrder,
                    status: 'complete'
                }
            };
        }
        if (action instanceof ErrorReserve) {
            if (!previous.reservationOrder)
                return previous;
            return {
                ...previous,
                reservationOrder: {
                    ...previous.reservationOrder,
                    status: 'input'
                }
            };
        }
        return previous;
    };
};
function calculatorFromState(state, override = {}) {
    const priceGroups = override.priceGroups ?? state.loaded?.prices;
    const calendar = override.calendar ?? state.loaded?.calendar ?? null;
    const plan = override.plan ?? state.loaded?.plans.find(p => p.plan_id === state.reservation?.plan.id);
    const room = override.room ?? state.loaded?.rooms.find(r => r.sales_inventory_id === state.reservation?.room.id);
    const guestCounts = override.guestCounts
        ?? state.reservationOrder?.guestCounts
        ?? state.guestsInRooms.map(({ numberOfAdults: adult, numberOfChildren: childA }) => ({ adult, childA, childB: 0, childC: 0, childD: 0 }));
    const range = override.range
        ?? (state.from && state.to
            ? [new Date(state.from), new Date(state.to)]
            : null);
    if (priceGroups
        &&
            plan
        &&
            room
        &&
            guestCounts) {
        return new BookingCalculatorInOrder(plan, room, priceGroups, calendar, guestCounts, range);
    }
    return null;
}
