import { deepCopy } from '../functions';
import { EntityFromSchema, $Schema, $EntityKeys } from '@~/domains/Entities/EntityFromSchema';
import Schema from '../schemas/House.yml';
import { ChildCategory } from './ChildCategory';
export class House extends EntityFromSchema {
    [$Schema] = Schema;
    [$EntityKeys] = ['house_id', 'host_id'];
    house_id;
    host_id;
    display_name;
    email;
    rawChildCategories;
    questions;
    cancel_block_days;
    privacy_policy_url;
    constructor(data) {
        super();
        this.house_id = data.house_id;
        this.host_id = data.host_id;
        this.display_name = data.display_name ?? '';
        this.email = data.email ?? '';
        this.rawChildCategories = deepCopy(data.child_categories ?? []);
        this.questions = deepCopy(data.questions ?? []);
        this.privacy_policy_url = data.privacy_policy_url ?? null;
        this.cancel_block_days = data.cancel_block_days ?? 0;
        this.validate();
    }
    get child_categories() {
        return this.rawChildCategories.map(d => new ChildCategory(d));
    }
    toSerializable() {
        return {
            house_id: this.house_id,
            host_id: this.host_id,
            display_name: this.display_name,
            email: this.email,
            child_categories: deepCopy(this.rawChildCategories),
            privacy_policy_url: this.privacy_policy_url ?? '',
            questions: deepCopy(this.questions),
            cancel_block_days: this.cancel_block_days,
        };
    }
    equalTo(target) {
        return target.house_id === this.house_id;
    }
}
