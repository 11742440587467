import { AlertMessage } from '@~/components/Actions/MessageActions';
import { LoadedCalender, LoadedHouses } from '../Actions/LoadedActions';
import { ChangeMonth } from '@~/components/Actions/ChangeDateActions';
export default (dispatch) => {
    return ({ action, previous, next }) => {
        if (action instanceof LoadedHouses) {
            if (action.data instanceof Error) {
                dispatch(new AlertMessage(action.data.message));
            }
        }
        if (action instanceof LoadedCalender) {
            if (previous.reservation) {
                dispatch(new ChangeMonth(previous.reservation.yearMonth));
            }
        }
    };
};
