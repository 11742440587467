import CacheContainer from "./CacheContainer";
export class FetchError extends Error {
}
export class PublishedDataFetcher {
    api;
    cache = new CacheContainer();
    constructor(api) {
        this.api = api;
    }
    async fetchInit() {
        return await this.cache.through('init', async () => {
            const response = await this.api.publishedInitial();
            if (response.status === 200)
                return response.body;
            throw new FetchError(response.body.message);
        });
    }
    async fetchDetail(house_id) {
        return await this.cache.through(`House-${house_id}`, async () => {
            const response = await this.api.publishedHouseDetail({ house_id });
            if (response.status === 200)
                return response.body;
            throw new FetchError(response.body.message);
        });
    }
    async fetchPrices(house_id) {
        return await this.cache.through(`Price-${house_id}`, async () => {
            const response = await this.api.pricesOfHouse({ house_id });
            if (response.status === 200)
                return response.body;
            throw new FetchError(response.body.message);
        });
    }
    async fetchCalendar(house_id, first, last) {
        const response = await this.api.calenderOfHouse({ house_id, first, last });
        if (response.status === 200)
            return response.body;
        throw new FetchError(response.body.message);
    }
    async host() {
        const init = await this.fetchInit();
        return init.host;
    }
    async site() {
        const init = await this.fetchInit();
        return init.site;
    }
    async *houses() {
        const init = await this.fetchInit();
        yield* init.houses;
    }
    async *plansOfHouse(house_id) {
        const detail = await this.fetchDetail(house_id);
        yield* detail.plans;
    }
    async *roomsOfHouse(house_id) {
        const detail = await this.fetchDetail(house_id);
        yield* detail.rooms;
    }
    async *pricesOfHouse(house_id) {
        const detail = await this.fetchPrices(house_id);
        yield* detail.priceGroups;
    }
    async *calenderOfHouse(house_id, first, last) {
        const detail = await this.fetchCalendar(house_id, first, last);
        yield* detail.calendar;
    }
    async tentativeReservation(trading_id, hash_code) {
        const response = await this.api.retrieveReservation({ tid: trading_id, hc: hash_code });
        if (response.status === 200)
            return response.body;
        return null;
    }
}
