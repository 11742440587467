import React from 'react';
import scoped from '../lib/Scoped';
import css from './ToggleSwitch.sass';
;
export default scoped({ css }, ({ value, onChange }) => (React.createElement(React.Fragment, null,
    React.createElement("input", { type: "checkbox", checked: value, onChange: (e) => { onChange(e.target.checked); } }),
    React.createElement("label", null))));
export const argTypes = {
    value: {
        type: 'boolean',
        defaultValue: false
    },
    onChange: {
        type: 'function'
    }
};
export const callbacks = (updateArgs) => ({
    onChange: (value) => updateArgs({ value })
});
