import React, { useEffect, useRef } from 'react';
import scoped from '../lib/Scoped';
import css from './DropDown.sass';
export default scoped({ css }, ({ display, isOpen, onFlip, children }) => {
    const popRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen
                &&
                    popRef.current
                &&
                    !event.composedPath().includes(popRef.current)) {
                onFlip(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onFlip]);
    return (React.createElement("div", { className: "dropdown", ref: popRef },
        React.createElement("div", { className: 'display', onClick: () => onFlip(true) }, display),
        isOpen
            &&
                React.createElement("div", { className: "dropdown-menu" }, children)));
});
export const argTypes = {
    display: {
        type: 'string',
        defaultValue: 'TEST'
    },
    isOpen: {
        type: 'boolean',
    },
    onFlip: {
        type: 'function'
    },
    children: {
        defaultValue: React.createElement("div", { style: {
                width: 400,
                height: 300,
                borderRadius: 20,
                background: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '5em'
            } }, "TEST")
    }
};
export const callbacks = (updateArgs, prev) => ({
    onFlip: isOpen => updateArgs({ isOpen }),
});
