import { UrlAction, InitiatePage } from '../Actions/UrlActions';
import { SetupIframe, MessageFromIframe } from '../Actions/IframeActions';
import { MessagingJsonRpcServer } from '../Services/MessagingJsonRpc';
import { HideIframe, LoadedHost, ShowIframe } from '../Actions/LoadedActions';
import { TransitionToGuestForm } from '@~/web/@Components/Actions/TransitionActions';
export default (dispatch) => {
    const iframe = document.createElement('iframe');
    const iframeParams = {
        memberPageOrigin: null,
        oneTimeToken: null,
    };
    const messagingServer = new MessagingJsonRpcServer({
        getOneTimeToken: () => iframeParams.oneTimeToken,
        getPage: () => new URL(location.href).searchParams.get('booking-inline'),
        getParentWidgetUrl: () => location.href,
        close: () => { location.search = ''; },
        showMemberPage: () => { location.search = '?booking-inline=member-page'; },
        logout: () => {
            localStorage.removeItem('BookingEngine_LoginStatus:expires');
            localStorage.removeItem('BookingEngine_LoginStatus:name');
            localStorage.removeItem('BookingEngine_LoginStatus:sessionId');
        },
        sendAccountInfo: (info) => {
            dispatch(new TransitionToGuestForm(info, info.memberId));
        },
        sendLoginStatus: (expires, name, sessionId) => {
            localStorage.setItem('BookingEngine_LoginStatus:expires', expires);
            localStorage.setItem('BookingEngine_LoginStatus:name', name);
            if (sessionId)
                localStorage.setItem('BookingEngine_LoginStatus:sessionId', sessionId);
        },
        getSessionId: () => {
            const expires = localStorage.getItem('BookingEngine_LoginStatus:expires');
            return expires && Number(expires) > Date.now()
                ? localStorage.getItem('BookingEngine_LoginStatus:sessionId') : null;
        }
    }, (origin) => origin === iframeParams.memberPageOrigin);
    window.addEventListener('message', event => {
        if (event.source && event.source === iframe.contentWindow && event.data.method) {
            dispatch(new MessageFromIframe(iframe.contentWindow, event));
        }
    });
    function updateInline(base) {
        const page = new URL(location.href).searchParams.get('booking-inline');
        if (page) {
            const url = new URL(base);
            url.searchParams.append('page', page);
            if (iframe.src !== url.href)
                iframe.src = url.href;
            dispatch(new ShowIframe);
        }
        else {
            const url = new URL(iframe.src || base.href);
            url.searchParams.delete('page');
            if (iframe.src !== url.href)
                iframe.src = url.href;
            dispatch(new HideIframe);
        }
    }
    return ({ action, previous, next }) => {
        iframeParams.memberPageOrigin = next.host?.memberPageUrl.origin ?? null;
        iframeParams.oneTimeToken = next.oneTimeToken ?? null;
        if (action instanceof InitiatePage) {
            dispatch(new SetupIframe(iframe));
        }
        if (action instanceof LoadedHost) {
            if (next.host) {
                updateInline(next.host.memberPageUrl);
            }
            ;
        }
        if (action instanceof UrlAction) {
            if (next.host) {
                updateInline(next.host.memberPageUrl);
            }
        }
        if (action instanceof MessageFromIframe) {
            messagingServer.onMessage(action.event, action.source);
        }
    };
};
