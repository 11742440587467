import React from 'react';
import { ChangeSearchCondition, ExecuteSearch } from '@~/components/Actions/SearchActions';
import scoped from '../lib/Scoped';
import DateRangePicker from '../Molecules/DateRangePicker';
import Select from '../Molecules/Select';
import css from './Widget.sass';
import MaterialIcons from '../styles/MaterialIcons';
import GuestsNumberInput from '../Molecules/GuestsNumberInput';
export default scoped({ css }, ({ hotels, selectedHotel, from, to, guestsInRooms, dispatch }) => {
    return (React.createElement("div", { className: 'widget' },
        React.createElement("div", null, hotels
            &&
                Object.keys(hotels).length > 1
            &&
                React.createElement(Select, { options: hotels, selected: selectedHotel, placeholder: "\u65BD\u8A2D\u9078\u629E", onChange: (selectedHotel) => dispatch(new ChangeSearchCondition(selectedHotel, from ? new Date(from) : null, to ? new Date(to) : null, guestsInRooms)) })),
        React.createElement("div", { className: 'period-wrapper' },
            React.createElement("span", null, "\u5BBF\u6CCA\u65E5"),
            React.createElement(DateRangePicker, { from: from, to: to, displayFormat: 'M\u6708dd\u65E5', onChange: (from, to) => dispatch(new ChangeSearchCondition(selectedHotel, from ? new Date(from) : null, to ? new Date(to) : null, guestsInRooms)) })),
        React.createElement("div", { className: 'rooms-wrapper' },
            React.createElement("span", null, "\u4EBA\u6570\u30FB\u90E8\u5C4B\u6570"),
            React.createElement(GuestsNumberInput, { guestsInRooms: guestsInRooms, onChange: guestsInRooms => {
                    dispatch(new ChangeSearchCondition(selectedHotel, from ? new Date(from) : null, to ? new Date(to) : null, guestsInRooms));
                } })),
        React.createElement("button", { className: 'search-button', onClick: () => dispatch(new ExecuteSearch()) },
            MaterialIcons.search,
            React.createElement("span", null, "\u3053\u306E\u6761\u4EF6\u3067\u691C\u7D22\u3059\u308B"))));
});
export const argTypes = {
    hotels: {
        control: 'object',
        defaultValue: {
            1: 'AAAA',
            2: 'BBBB',
            3: 'CCCC',
        }
    },
    selectedHotel: {
        control: 'number'
    },
    from: {
        control: 'date'
    },
    to: {
        control: 'date'
    },
    guestsInRooms: {
        control: { type: 'object' },
        defaultValue: [{
                numberOfAdults: 1,
                numberOfChildren: 0
            }]
    },
    dispatch: {
        type: 'function'
    },
};
export const callbacks = (updateArgs) => ({
    dispatch: (action) => {
        if (action instanceof ChangeSearchCondition) {
            updateArgs({
                selectedHotel: action.selectedHotel,
                from: action.from?.getTime() ?? undefined,
                to: action.to?.getTime() ?? undefined,
                guestsInRooms: action.guestsInRooms
            });
            return;
        }
    }
});
