import { AlertMessage } from '@~/components/Actions/MessageActions';
export default function messageReducer(action, previous) {
    if (action instanceof AlertMessage) {
        return {
            ...previous,
            alertMessage: action.message
        };
    }
    return previous;
}
