import Renderer from '@~/components/lib/Renderer';
import BookingEngine from '@~/components/Templates/BookingEngine';
import { ApplicationAction } from './Applications/Actions/ApplicationAction';
import { scriptTag, functions, debug } from './Adapters/ScriptTag';
import Store from './Flux/Store';
import { hookPopStateUrl } from './Adapters/DispatchHooks';
import createReducer from './Applications/Reducers/BookingEngineReducer';
import createObserver from './Applications/Observers/BookingEngineObserver';
import { InitiatePage } from './Applications/Actions/UrlActions';
import Dispatcher from './Flux/Dispatcher';
import { SwaggerClient } from './Adapters/ApiClient/SwaggerClient';
import { debugWidgetStore } from './debugWidgetStore';
const sessionExpires = localStorage.getItem('BookingEngine_LoginStatus:expires');
const isLoggedIn = sessionExpires ? parseInt(sessionExpires) > Date.now() : false;
const initial = {
    hotels: null,
    selectedHotel: Number(scriptTag?.getAttribute('hotel')) || null,
    guestsInRooms: [{ numberOfAdults: 1, numberOfChildren: 0 }],
    showMemberSystem: false,
    loginStatus: {
        isLoggedIn,
        userName: isLoggedIn ? localStorage.getItem('BookingEngine_LoginStatus:name') || '' : undefined,
    }
};
const api = new SwaggerClient(functions);
const canonical = document.querySelector('link[rel="canonical"]');
const dispatcher = new Dispatcher();
const reducer = createReducer(pict => new URL(`./files/${pict.key}`, functions).href);
const observer = createObserver(dispatcher, api, new URL(canonical?.href ?? '.', location.href));
const store = new Store(initial, reducer);
store.observedBy(observer);
const element = document.createElement('div');
element.classList.add('hotel-smart-account-page');
const renderer = new Renderer(element, BookingEngine, store, dispatcher.dispatch);
if (scriptTag)
    renderer.appendNextTo(scriptTag);
dispatcher.registerStore(store);
hookPopStateUrl(dispatcher.dispatch);
dispatcher.dispatch(new InitiatePage(new URL(location.href)));
if (debug) {
    ApplicationAction.debugTrace = true;
    debugWidgetStore(store);
}
