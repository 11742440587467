import searchReducer from './searchReducer';
import urlReducer from './urlReducer';
import loadedReducer from './loadedReducer';
import messageReducer from './messageReducer';
import reservationReducer from './reservationReducer';
function mergeReducers(...reducers) {
    return (action, state) => reducers.reduce((prev, reducer) => reducer(action, prev), state);
}
;
export default (pictureToUrl) => mergeReducers(messageReducer, urlReducer(pictureToUrl), loadedReducer(pictureToUrl), searchReducer(pictureToUrl), reservationReducer(10));
