export default class Dispatcher {
    storeList = [];
    actionsQueue = [];
    queueProcess = null;
    registerStore(store) {
        this.storeList.push(store);
    }
    dispatch = (action) => {
        this.actionsQueue.push(action);
        if (!this.queueProcess) {
            this.queueProcess = this.kickQueue();
        }
    };
    async kickQueue() {
        try {
            while (this.actionsQueue.length) {
                const queue = this.actionsQueue;
                this.actionsQueue = [];
                queue.forEach(action => this.storeList.forEach(store => store.reduce(action)));
                await new Promise(requestAnimationFrame);
            }
        }
        finally {
            this.queueProcess = null;
        }
    }
}
