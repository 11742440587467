import React from 'react';
import ScreenShadow from '../Atoms/ScreenShadow';
import scoped from '../lib/Scoped';
import Button from '../Atoms/Button';
import css from './Alert.sass';
const Balloon = scoped({ css }, ({ message, onClose }) => (React.createElement("div", { className: 'alert-dialog' },
    React.createElement("pre", null, message),
    React.createElement(Button, { label: '\u9589\u3058\u308B', beforeIcon: 'close', onClick: onClose }))));
export default (props) => (React.createElement(ScreenShadow, { onClick: props.onClose },
    React.createElement(Balloon, { ...props })));
export const argTypes = {
    message: {
        type: 'string'
    },
    onClose: {
        type: 'function'
    },
};
export const callbacks = () => ({
    onClose: () => null
});
