import { BookingCalculatorInSearch } from '@~/domains/Services/BookingCalculatorInSearch';
import { BookingCalculatorInOrder } from '@~/domains/Services/BookingCalculatorInOrder';
export function debugWidgetStore(store) {
    store.observedBy(r => {
        console.debug(r);
        if ('stack' in r.action) {
            console.debug(r.action.stack);
        }
    });
    Object.defineProperty(window, 'store', { value: store });
    Object.defineProperty(window, 'calculatorInSearch', { value: () => {
            const { guestsInRooms, from, to, loaded } = store.getState();
            const guestCounts = guestsInRooms.map(({ numberOfAdults: adult, numberOfChildren: childA }) => ({ adult, childA, childB: 0, childC: 0, childD: 0 }));
            const range = from && to ? [new Date(from), new Date(to)] : null;
            return new BookingCalculatorInSearch(loaded.prices, loaded.calendar, guestCounts, range);
        } });
    Object.defineProperty(window, 'calculatorInOrder', { value: () => {
            const { reservationOrder, guestsInRooms, from, to, loaded, reservation } = store.getState();
            const guestCounts = reservationOrder?.guestCounts
                ?? guestsInRooms.map(({ numberOfAdults: adult, numberOfChildren: childA }) => ({ adult, childA, childB: 0, childC: 0, childD: 0 }));
            const range = from && to ? [new Date(from), new Date(to)] : null;
            const plan = loaded?.plans.find(p => p.plan_id === reservation?.plan.id);
            const room = loaded?.rooms.find(r => r.sales_inventory_id === reservation?.room.id);
            return new BookingCalculatorInOrder(plan, room, loaded.prices, loaded.calendar, guestCounts, range);
        } });
}
