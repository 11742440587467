import { BookingCalculatorInOrder } from "@~/domains/Services/BookingCalculatorInOrder";
/** @deprecated 呼び出し先の用途に応じて引数を調整した関数をそれぞれ作ること */
export function calculatorFromState(state, override = {}) {
    const priceGroups = override.priceGroups ?? state.loaded?.prices;
    const calendar = override.calendar ?? state.loaded?.calendar ?? null;
    const plan = override.plan ?? state.loaded?.plans.find(p => p.plan_id === state.reservation?.plan.id);
    const room = override.room ?? state.loaded?.rooms.find(r => r.sales_inventory_id === state.reservation?.room.id);
    const guestCounts = override.guestCounts
        ?? state.reservationOrder?.guestCounts
        ?? state.guestsInRooms.map(({ numberOfAdults: adult, numberOfChildren: childA }) => ({ adult, childA, childB: 0, childC: 0, childD: 0 }));
    const range = override.range
        ?? (state.from && state.to
            ? [new Date(state.from), new Date(state.to)]
            : null);
    if (priceGroups
        &&
            plan
        &&
            room
        &&
            guestCounts) {
        return new BookingCalculatorInOrder(plan, room, priceGroups, calendar, guestCounts, range);
    }
    return null;
}
export function displayRoom(room, pictureToUrl) {
    return {
        id: room.sales_inventory_id,
        displayName: room.display_name,
        pictures: room.pictures.map(pictureToUrl),
        recommendation: { ...room.recommendation },
        article: { ...room.article },
        introduction: room.introduction,
        features: [...room.features]
    };
}
export function displayPlan(plan, pictureToUrl) {
    return {
        id: plan.plan_id,
        displayName: plan.display_name,
        pictures: plan.pictures.map(pictureToUrl),
        introduction: plan.introduction,
        features: [...plan.features],
        recommendation: { ...plan.recommendation },
        cancelPolicies: [...plan.cancel_policies],
    };
}
export function toSingleByte(src) {
    return src
        .replace(/[Ａ-Ｚａ-ｚ０-９]/g, char => String.fromCharCode(char.charCodeAt(0) - 0xFEE0))
        .replace(/‐―－ー/g, '-')
        .replace(/＋/g, '+')
        .replace(/＠/g, '@')
        .replace(/．/g, '.')
        .replace(/＿/g, '_');
}
