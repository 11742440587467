import React, { useEffect, useRef } from 'react';
import scoped from '../lib/Scoped';
import css from './ScreenShadow.sass';
export default scoped({ css }, ({ children, onClick }) => {
    const shadow = useRef(null);
    const stopPop = (ev) => ev.stopPropagation();
    const events = [
        'mousewheel',
        'touchmove',
    ];
    useEffect(() => {
        events.forEach(type => shadow.current?.addEventListener(type, stopPop, { passive: false }));
        return () => events.forEach(type => shadow.current?.removeEventListener(type, stopPop));
    });
    const listenClick = (ev) => {
        if (ev.target === shadow.current)
            onClick?.();
        ev.stopPropagation();
    };
    return (React.createElement("div", { className: 'screen', onClick: listenClick, ref: shadow }, children));
});
export const argTypes = {
    onClick: {
        type: 'function'
    },
    children: {
        defaultValue: React.createElement("div", { style: {
                width: 400,
                height: 300,
                borderRadius: 20,
                background: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '5em'
            } }, "TEST")
    }
};
export const callbacks = () => ({
    onClick: () => null
});
