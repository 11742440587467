import { Site } from '@~/domains/Entities/Site';
import { Host } from '@~/domains/Entities/Host';
import { ChangeResultsGroup, ChangeResultsOrder } from '@~/components/Actions/SearchActions';
import { LoadedHouses, LoadedHouseDetail, LoadedCalender, CatchOneTimeToken, LoadedSite, LoadedHost, ShowIframe, HideIframe } from '../Actions/LoadedActions';
import { displayPlan, displayRoom } from './utility';
import { BookingCalculatorInSearch } from '@~/domains/Services/BookingCalculatorInSearch';
export default (pictureToUrl) => {
    return (action, previous) => {
        if (action instanceof LoadedSite) {
            if (action.data instanceof Site) {
                return {
                    ...previous,
                    site: action.data,
                };
            }
            return previous;
        }
        if (action instanceof LoadedHouses) {
            if (action.data instanceof Array) {
                const hotels = Object.fromEntries(action.data.map(h => ([h.house_id, h.display_name])));
                const selectedHotel = action.data.length === 1
                    ? action.data[0].house_id
                    : (previous.selectedHotel ?? '') in hotels
                        ? previous.selectedHotel
                        : null;
                return {
                    ...previous,
                    hotels,
                    selectedHotel,
                };
            }
            return previous;
        }
        if (action instanceof LoadedHost) {
            if (action.data && action.data instanceof Host) {
                return {
                    ...previous,
                    host: action.data,
                };
            }
            return previous;
        }
        if (action instanceof ShowIframe) {
            return {
                ...previous,
                showIframe: true
            };
        }
        if (action instanceof HideIframe) {
            return {
                ...previous,
                showIframe: false
            };
        }
        if (action instanceof CatchOneTimeToken) {
            return {
                ...previous,
                oneTimeToken: action.oneTimeToken
            };
        }
        if (action instanceof LoadedHouseDetail) {
            const [results, loaded] = action.data instanceof Error
                ||
                    action.data === 'loading'
                ? [action.data, undefined]
                : [
                    searchResult({
                        ...previous,
                        loaded: action.data,
                    }),
                    action.data
                ];
            return {
                ...previous,
                searchResult: {
                    order: previous.searchResult?.order ?? 'recommended',
                    shownGroup: previous.searchResult?.shownGroup ?? 'plans',
                    results
                },
                loaded
            };
        }
        if (action instanceof LoadedCalender) {
            if (!previous.loaded)
                return previous;
            const remainCalender = previous.loaded.calendar?.filter(cal => !action.calendar.some(n => n.date === cal.date)) ?? [];
            return {
                ...previous,
                loaded: {
                    ...previous.loaded,
                    calendar: [
                        ...remainCalender,
                        ...action.calendar,
                    ]
                }
            };
        }
        if (action instanceof ChangeResultsGroup) {
            const results = previous.searchResult?.results instanceof Array
                &&
                    previous.loaded
                ? searchResult(previous, action.shownGroup)
                : previous.searchResult?.results;
            return {
                ...previous,
                searchResult: {
                    order: previous.searchResult?.order ?? 'recommended',
                    shownGroup: action.shownGroup,
                    results
                }
            };
        }
        if (action instanceof ChangeResultsOrder) {
            const results = previous.searchResult?.results instanceof Array
                &&
                    previous.loaded
                ? searchResult(previous, previous.searchResult.shownGroup, action.order)
                : previous.searchResult?.results;
            return {
                ...previous,
                searchResult: {
                    order: action.order,
                    shownGroup: previous.searchResult?.shownGroup ?? 'plans',
                    results
                }
            };
        }
        return previous;
    };
    function searchResult(state, shownGroup = 'plans', sortBy = 'recommended') {
        if (!state.loaded)
            return [];
        const loaded = state.loaded;
        const guestCounts = state.guestsInRooms.map(({ numberOfAdults: adult, numberOfChildren: childA }) => ({ adult, childA, childB: 0, childC: 0, childD: 0 }));
        const range = state.from && state.to ? [new Date(state.from), new Date(state.to)] : null;
        const calc = new BookingCalculatorInSearch(loaded.prices, loaded.calendar, guestCounts, range);
        if (shownGroup === 'rooms') {
            return loaded.rooms
                .map(room => ({
                room: { ...displayRoom(room, pictureToUrl), timestamp: room.updated_at.getTime() },
                plans: loaded.plans
                    .filter(plan => calc.available(plan, room))
                    .map(plan => ({
                    ...displayPlan(plan, pictureToUrl),
                    numberOfNights: calc.numberOfNights,
                    numberOfAdults: calc.numberOfAdults,
                    numberOfChildren: calc.numberOfChildren,
                    specialPrice: calc.totalPrice(plan, room),
                    regularPrice: null,
                    timestamp: plan.updated_at.getTime(),
                }))
                    .sort((a, b) => {
                    return sortBy === 'price'
                        ? a.specialPrice - b.specialPrice
                        : b.timestamp - a.timestamp;
                })
            }))
                .filter(r => r.plans.length)
                .sort((a, b) => {
                return sortBy === 'price'
                    ? a.plans[0].specialPrice - b.plans[0].specialPrice
                    : b.room.timestamp - a.room.timestamp;
            });
        }
        else {
            return loaded.plans
                .map(plan => ({
                plan: { ...displayPlan(plan, pictureToUrl), timestamp: plan.updated_at.getTime() },
                rooms: loaded.rooms
                    .filter(room => calc.available(plan, room))
                    .map(room => ({
                    ...displayRoom(room, pictureToUrl),
                    numberOfNights: calc.numberOfNights,
                    numberOfAdults: calc.numberOfAdults,
                    numberOfChildren: calc.numberOfChildren,
                    specialPrice: calc.totalPrice(plan, room),
                    regularPrice: null,
                    timestamp: room.updated_at.getTime(),
                }))
                    .sort((a, b) => {
                    return sortBy === 'price'
                        ? a.specialPrice - b.specialPrice
                        : b.timestamp - a.timestamp;
                })
            }))
                .filter(p => p.rooms.length)
                .sort((a, b) => {
                return sortBy === 'price'
                    ? a.rooms[0].specialPrice - b.rooms[0].specialPrice
                    : b.plan.timestamp - a.plan.timestamp;
            });
        }
    }
};
