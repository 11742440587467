import { Reserve, TransitionToPayment } from '@~/components/Actions/ReserveActions';
import { CompleteReserve, ErrorReserve } from '../Actions/ReserveActions';
import { AlertMessage } from '@~/components/Actions/MessageActions';
import { TransitionToLoginForm } from '@~/components/Actions/TransitionActions';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { ReservationConflicted } from '../Exceptions/ReservationConflicted';
import { MessageFromIframe } from '../Actions/IframeActions';
export default (dispatch, sender, loadInformation) => ({ action, previous, next }) => {
    if (action instanceof TransitionToLoginForm) {
        if (previous.host) {
            const url = new URL(previous.host.memberPageUrl);
            url.searchParams.append('action', action.afterAction);
            url.searchParams.set('page', 'login');
            url.searchParams.set('widget-url', `${location.origin}${location.pathname}`);
            const handler = open(url, 'loginWindow');
            if (handler) {
                window.addEventListener('message', event => {
                    if (event.source && event.source === handler && event.data.method) {
                        dispatch(new MessageFromIframe(handler, event));
                    }
                });
            }
        }
    }
    if (action instanceof Reserve) {
        if (validation(previous, dispatch)) {
            booking(previous, dispatch, sender);
        }
    }
    if (action instanceof TransitionToPayment) {
        if (validation(previous, dispatch)) {
            transitionToPayment(previous, dispatch, sender);
        }
    }
    if (next.reservation?.yearMonth
        &&
            next.reservation.yearMonth !== previous.reservation?.yearMonth
        &&
            !next.reservation.priceList) {
        if (!next.selectedHotel)
            return;
        const firstDate = startOfMonth(new Date(next.reservation.yearMonth));
        loadInformation.priceCalender(next.selectedHotel, firstDate, endOfMonth(firstDate), dispatch);
        const nextMonth = addMonths(firstDate, 1);
        loadInformation.priceCalender(next.selectedHotel, nextMonth, endOfMonth(nextMonth), dispatch);
    }
};
const emailRegExp = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
function validation(previous, dispatch) {
    const invalids = [];
    if (!previous.reservationOrder?.planId)
        invalids.push('プランが選択されていません');
    if (!previous.reservationOrder?.roomTypeId)
        invalids.push('部屋タイプが選択されていません');
    if (!previous.reservationOrder?.checkin)
        invalids.push('利用開始日が入力されていません');
    if (!previous.reservationOrder?.checkout)
        invalids.push('利用終了日が入力されていません');
    if (!previous.reservationOrder?.guestInfo.name)
        invalids.push('お名前が入力されていません');
    if (!previous.reservationOrder?.guestInfo.nameRuby)
        invalids.push('お名前(ふりがな)が入力されていません');
    if (!previous.reservationOrder?.guestInfo.email) {
        invalids.push('メールアドレスが入力されていません');
    }
    else if (!previous.reservationOrder.guestInfo.email.match(emailRegExp)) {
        invalids.push('メールアドレスの書式に誤りがあります');
    }
    if (!previous.reservationOrder?.guestInfo.phone) {
        invalids.push('電話番号が入力されていません');
    }
    const guestsRange = previous.loaded?.rooms.find(r => r.sales_inventory_id === previous.reservation?.room.id)?.guests_range;
    const guestCounts = previous.reservationOrder?.guestCounts.map(c => (c.adult + c.childA + c.childB + c.childC + c.childD)) ?? [];
    if (guestsRange && guestCounts.some(num => num < guestsRange.min || guestsRange.max < num)) {
        invalids.push(`宿泊人数が制限を超えている部屋があります (${guestsRange.min} ~ ${guestsRange.max}人)`);
    }
    previous.reservationOrder?.questionsAndAnswers
        .filter((qa) => qa.question.required && !qa.answer)
        .forEach((qa) => invalids.push(`質問項目「${qa.question.title}」が未入力です`));
    if (invalids.length) {
        dispatch(new ErrorReserve('invalid props.'));
        dispatch(new AlertMessage(['※入力に誤りがあります', ...invalids].join('\n・')));
    }
    return invalids.length === 0;
}
async function booking(previous, dispatch, sender) {
    if (!previous.site)
        return;
    if (!previous.loaded)
        return;
    // if (!previous.reservation) return;
    if (previous.reservationOrder?.status !== 'input' || !previous.reservationOrder.checkin || !previous.reservationOrder.checkout)
        return;
    const totalFee = previous.reservationOrder.roomBreakdown.reduce((sum, cur) => sum + cur.subTotal, 0);
    const memo = previous.reservationOrder.questionsAndAnswers.reduce((memo, qa) => memo + '[' + qa.question.title + ']' + '\n' + qa.answer + '\n\n\n', '');
    try {
        const result = await sender.book({
            site_url: previous.site.base_url,
            house_id: previous.loaded.house.house_id,
            plan_id: previous.reservationOrder.planId,
            sales_inventory_id: previous.reservationOrder.roomTypeId,
            checkin: previous.reservationOrder.checkin,
            checkout: previous.reservationOrder.checkout,
            guestName: previous.reservationOrder.guestInfo.name,
            guestNameRuby: previous.reservationOrder.guestInfo.nameRuby,
            email: previous.reservationOrder.guestInfo.email,
            phone: previous.reservationOrder.guestInfo.phone,
            num_rooms: previous.reservationOrder.guestCounts.length,
            guestCounts: previous.reservationOrder.guestCounts,
            member_id: previous.reservationOrder.memberId,
            memo,
            totalFee,
        });
        dispatch(new CompleteReserve());
    }
    catch (err) {
        console.error(err);
        const debugMessage = err instanceof Error ? err.message : '';
        dispatch(new ErrorReserve(debugMessage));
        const alertMessage = err instanceof ReservationConflicted ? err.message : '予約の送信に失敗しました';
        dispatch(new AlertMessage(alertMessage));
    }
}
async function transitionToPayment(previous, dispatch, sender) {
    if (!previous.site)
        return;
    if (!previous.loaded)
        return;
    if (previous.reservationOrder?.status !== 'input' || !previous.reservationOrder.checkin || !previous.reservationOrder.checkout)
        return;
    const totalFee = previous.reservationOrder.roomBreakdown.reduce((sum, cur) => sum + cur.subTotal, 0);
    const memo = previous.reservationOrder.questionsAndAnswers.reduce((memo, qa) => memo + '[' + qa.question.title + ']' + '\n' + qa.answer + '\n\n\n', '');
    try {
        const previousForm = document.getElementById('BookingEnginePaymentForm');
        if (previousForm && new FormData(previousForm).get('trading_id')) {
            previousForm.submit();
            return;
        }
        const result = await sender.createTentativeReservation({
            site_url: previous.site.base_url,
            house_id: previous.loaded.house.house_id,
            plan_id: previous.reservationOrder.planId,
            sales_inventory_id: previous.reservationOrder.roomTypeId,
            checkin: previous.reservationOrder.checkin,
            checkout: previous.reservationOrder.checkout,
            guestName: previous.reservationOrder.guestInfo.name,
            guestNameRuby: previous.reservationOrder.guestInfo.nameRuby,
            email: previous.reservationOrder.guestInfo.email,
            phone: previous.reservationOrder.guestInfo.phone,
            num_rooms: previous.reservationOrder.guestCounts.length,
            guestCounts: previous.reservationOrder.guestCounts,
            member_id: previous.reservationOrder.memberId,
            memo,
            totalFee,
            orderState: previous.reservationOrder,
            path: location.pathname,
            tradingId: previous.tradingId
        });
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', result.requestUrl);
        form.setAttribute('id', 'BookingEnginePaymentForm');
        Object.entries(result.formData).forEach(([key, value]) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', key);
            input.setAttribute('value', value);
            form.appendChild(input);
        });
        document.body.appendChild(form);
        form.submit();
    }
    catch (err) {
        console.error(err);
        const debugMessage = err instanceof Error ? err.message : '';
        dispatch(new ErrorReserve(debugMessage));
        const alertMessage = err instanceof ReservationConflicted ? err.message : '支払いページへの遷移に失敗しました';
        dispatch(new AlertMessage(alertMessage));
    }
}
