"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotMatchToSchema = exports.validateMatchToSchema = void 0;
var json_schema_1 = require("json-schema");
function validateMatchToSchema(schema, instance) {
    var result = (0, json_schema_1.validate)(instance, schema);
    if (!result.valid) {
        throw new NotMatchToSchema(instance, schema, result.errors);
    }
}
exports.validateMatchToSchema = validateMatchToSchema;
var NotMatchToSchema = /** @class */ (function () {
    function NotMatchToSchema(instance, schema, details) {
        this.instance = instance;
        this.schema = schema;
        this.details = details;
        this.rawStack = new Error().stack;
    }
    Object.defineProperty(NotMatchToSchema.prototype, "name", {
        get: function () {
            return this.constructor.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotMatchToSchema.prototype, "message", {
        get: function () {
            return this.details.map(function (e) { return "[".concat(e.property, "] ").concat(e.message); }).join('\n');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotMatchToSchema.prototype, "stack", {
        get: function () {
            if (!this.rawStack)
                return;
            var trace = this.rawStack
                .split('\n')
                .slice(2)
                .join('\n');
            return "".concat(this.name, ":\n").concat(this.message, "\n").concat(trace);
        },
        enumerable: false,
        configurable: true
    });
    return NotMatchToSchema;
}());
exports.NotMatchToSchema = NotMatchToSchema;
Object.setPrototypeOf(NotMatchToSchema.prototype, Error.prototype);
