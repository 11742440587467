import { ApplicationAction } from './ApplicationAction';
const LoadFailedKey = Symbol();
export class LoadFailed extends Error {
    [LoadFailedKey];
}
export class LoadedAction extends ApplicationAction {
    data;
    constructor(data) {
        super();
        this.data = data;
    }
    get isOk() {
        return !(this.data instanceof LoadFailed);
    }
}
const LoadedSiteKey = Symbol();
export class LoadedSite extends LoadedAction {
    [LoadedSiteKey];
}
const LoadedHousesKey = Symbol();
export class LoadedHouses extends LoadedAction {
    [LoadedHousesKey];
}
const LoadedHouseDetailKey = Symbol();
export class LoadedHouseDetail extends LoadedAction {
    [LoadedHouseDetailKey];
}
const LoadedHostKey = Symbol();
export class LoadedHost extends LoadedAction {
    [LoadedHostKey];
}
export class LoadedCalender extends ApplicationAction {
    calendar;
    constructor(calendar) {
        super();
        this.calendar = calendar;
    }
}
export class ShowIframe extends ApplicationAction {
}
export class HideIframe extends ApplicationAction {
}
export class CatchOneTimeToken extends ApplicationAction {
    oneTimeToken;
    constructor(oneTimeToken) {
        super();
        this.oneTimeToken = oneTimeToken;
    }
}
const LoadedReservationKey = Symbol();
export class LoadedReservation extends LoadedAction {
    [LoadedReservationKey];
}
const LoadedReservationListKey = Symbol();
export class LoadedReservationList extends LoadedAction {
    [LoadedReservationListKey];
}
const LoadedAccountKey = Symbol();
export class LoadedAccount extends LoadedAction {
    [LoadedAccountKey];
}
export class LoadedCalenderInMyPage extends LoadedCalender {
    reservedRoomIds;
    dates;
    constructor(calendar, reservedRoomIds, dates) {
        super(calendar);
        this.reservedRoomIds = reservedRoomIds;
        this.dates = dates;
    }
}
