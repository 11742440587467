import { validateMatchToSchema } from 'openapi-validator';
import { ChildCategory as EnumCategory } from '../Enums';
import { EntityFromSchema, $Schema, $EntityKeys } from '@~/domains/Entities/EntityFromSchema';
import SiteSchema from '../schemas/House.yml';
const Schema = SiteSchema.properties.child_categories.items;
export class ChildCategory extends EntityFromSchema {
    [$Schema] = Schema;
    [$EntityKeys] = ['code'];
    code;
    display_name;
    constructor(data) {
        super();
        validateMatchToSchema(Schema, data);
        this.code = data.code;
        this.display_name = data.display_name;
    }
    toSerializable() {
        return {
            code: this.code,
            display_name: this.display_name,
        };
    }
    equalTo(target) {
        return target.code === this.code;
    }
    get key() {
        return EnumCategory[this.code];
    }
}
