export default class {
    reducer;
    state;
    observers = [];
    constructor(initialState, reducer) {
        this.reducer = reducer;
        this.state = initialState;
    }
    observedBy(observer) {
        this.observers.push(observer);
    }
    getState() {
        return this.state;
    }
    reduce(action) {
        const previous = this.getState();
        this.state = this.reducer(action, this.state);
        const next = this.getState();
        this.observers.forEach(ob => ob({ action, previous, next }));
    }
}
