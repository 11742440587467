import { differenceInCalendarDays, format } from "date-fns";
import { dateRange, sum } from "../functions";
import { PriceNotDecidable } from "../Exceptions/CalculationExceptions";
import { UnitPriceCalculator, mapGuests } from "./UnitPriceCalculator";
export class BookingCalculatorInSearch {
    priceGroups;
    calendar;
    guestCounts;
    range;
    unitPriceCalculator;
    constructor(priceGroups, calendar, guestCounts, range) {
        this.priceGroups = priceGroups;
        this.calendar = calendar;
        this.guestCounts = guestCounts;
        this.range = range;
        this.unitPriceCalculator = new UnitPriceCalculator(priceGroups, calendar);
    }
    get numberOfNights() {
        return this.range ? differenceInCalendarDays(this.range[1], this.range[0]) : 1;
    }
    get numberOfAdults() {
        return sum(this.guestCounts.map(g => g.adult));
    }
    get numberOfChildren() {
        return sum(this.guestCounts.map(g => g.childA + g.childB + g.childC + g.childD));
    }
    get numberOfRooms() {
        return this.guestCounts.length;
    }
    guestsInRoomLimit(room) {
        const guestCounts = this.guestCounts.map(g => sum(Object.values(g)));
        return Math.min(...guestCounts) >= room.guests_range.min
            && Math.max(...guestCounts) <= room.guests_range.max;
    }
    available(plan, room) {
        const matchPlanAndRoom = plan.sales_inventory_ids.includes(room.sales_inventory_id);
        const existsPriceGroup = Boolean(this.findPriceGroups(plan, room));
        return (matchPlanAndRoom
            &&
                existsPriceGroup
            &&
                this.guestsInRoomLimit(room)
            &&
                this.enoughVacancyInRange(room)
            &&
                this.priceDecidable(plan, room)
            &&
                this.totalPrice(plan, room) > 0);
    }
    vacancies(room, date) {
        return this.calendar
            ?.find(cal => cal.date === format(date, 'yyyy-MM-dd'))
            ?.vacancies.find(inv => inv.sales_inventory_id === room.sales_inventory_id)
            ?.remaining
            ?? 0;
    }
    enoughVacancyInDate(room, date) {
        if (!this.calendar)
            return true;
        return this.vacancies(room, date) >= this.numberOfRooms;
    }
    enoughVacancyInRange(room) {
        if (!this.calendar)
            return true;
        if (!this.range)
            return true;
        return dateRange(...this.range).every(date => this.enoughVacancyInDate(room, date));
    }
    findPriceGroups(plan, room) {
        return this.unitPriceCalculator.findPriceGroups(plan, room);
    }
    /** @throws PriceNotDecidable */
    totalPrice(plan, room) {
        if (!this.range)
            return this.minimumDailyTotal(plan, room);
        return sum(dateRange(...this.range).map(date => this.dailyTotal(plan, room, date)));
    }
    priceDecidable(plan, room) {
        try {
            this.totalPrice(plan, room);
            return true;
        }
        catch (err) {
            if (err instanceof PriceNotDecidable) {
                return false;
            }
            throw err;
        }
    }
    /** @throws PriceNotDecidable */
    dailyTotal(plan, room, date) {
        return sum(this.guestCounts.map(counts => this.unitPrice(plan, room, counts, date)));
    }
    /** @throws PriceNotDecidable */
    unitPrice(plan, room, guests, date) {
        return this.unitPriceCalculator.unitPrice(plan, room, guests, date, this.numberOfRooms);
    }
    /** @throws PriceNotDecidable */
    minimumDailyTotal(plan, room) {
        return sum(this.guestCounts.map(guests => this.minimumDailyPrice(plan, room, guests)));
    }
    /** @throws PriceNotDecidable */
    minimumDailyPrice(plan, room, guests) {
        try {
            return this.minimumDailyPriceByRoom(plan, room);
        }
        catch (err) {
            if (!(err instanceof PriceNotDecidable)) {
                throw err;
            }
        }
        try {
            return this.minimumDailyPriceByGuests(plan, room, guests);
        }
        catch (err) {
            if (!(err instanceof PriceNotDecidable)) {
                throw err;
            }
        }
        throw new PriceNotDecidable();
    }
    /** @throws PriceNotDecidable */
    minimumDailyPriceByRoom(plan, room) {
        const group = this.findPriceGroups(plan, room);
        if (group?.unit !== 'room')
            throw new PriceNotDecidable();
        const prices = group.prices
            .filter(({ rank_prices }) => rank_prices.length > 0)
            .map(({ rank_prices: prices }) => {
            const max = Math.max(...prices.map(p => p.quantity));
            const price = prices.find(r => r.quantity === Math.min(this.numberOfRooms, max))?.unit_price ?? null;
            if (price === null)
                throw new PriceNotDecidable();
            return price;
        });
        return Math.min(...prices) * this.numberOfRooms;
    }
    /** @throws PriceNotDecidable */
    minimumDailyPriceByGuests(plan, room, guests) {
        const group = this.findPriceGroups(plan, room);
        if (group?.unit !== 'guest')
            throw new PriceNotDecidable();
        const pricingGuestsNumber = this.pricingGuestsNumber(group, guests);
        const prices = group.prices
            .filter(({ rank_prices }) => rank_prices.length > 0)
            .map(({ rank_prices: prices }) => {
            const max = Math.max(...prices.map(p => p.quantity));
            const baseUnitPrice = prices.find(r => r.quantity === Math.min(pricingGuestsNumber, max))?.unit_price ?? null;
            if (baseUnitPrice === null)
                throw new PriceNotDecidable();
            return sum(mapGuests(guests, (childCategory, numGuest) => numGuest
                ? numGuest * this.childUnitPrice(group, baseUnitPrice, childCategory)
                : 0));
        });
        return Math.min(...prices);
    }
    pricingGuestsNumber(group, guests) {
        if (group.children.adjustBy !== 'override')
            return sum(Object.values(guests));
        // 単価を上書きする子供料金は値付け時に参照する数量から除外するが、単価が指定されていない子供区分の場合（値段がnull）は大人単価を参照するため除外しない
        return sum(mapGuests(guests, (childCategory, numGuest) => {
            const adjustVolume = group.children.volumes.find(v => v.child_category === childCategory)?.value ?? null;
            return adjustVolume === null ? numGuest : 0;
        }));
    }
    // TODO: このメソッドはUnitPriceCalculator にもあるため、共通化する
    childUnitPrice(group, baseUnitPrice, childCategory) {
        const adjustVolume = group.children.volumes.find(v => v.child_category === childCategory)?.value ?? null;
        if (adjustVolume !== null) {
            switch (group.children.adjustBy) {
                case 'subtract': return baseUnitPrice - adjustVolume;
                case 'percent': return Math[group.children.roundingBy](baseUnitPrice * adjustVolume / 100);
                case 'override': return adjustVolume;
            }
        }
        return baseUnitPrice;
    }
}
