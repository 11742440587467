import React from 'react';
import { addDays, differenceInCalendarDays, format } from 'date-fns';
import { ja } from 'date-fns/locale';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import scoped from '../lib/Scoped';
import packageCss from "!!raw-loader!react-datepicker/dist/react-datepicker.css";
import localCss from './DateRangePicker.sass';
import MaterialIcons from '../styles/MaterialIcons';
import ToggleSwitch from '../Atoms/ToggleSwitch';
registerLocale('ja', ja);
setDefaultLocale('ja');
export default scoped({
    css: [
        packageCss,
        localCss
    ]
}, ({ from, to, displayFormat = 'yyyy年MM月dd日', minDate = new Date(), isClearable = false, onChange }) => {
    const startDate = from ? new Date(from) : null;
    const endDate = to ? new Date(to) : null;
    const setRange = ([start, end]) => onChange?.(start ? format(start, 'yyyy-MM-dd') : null, end ? format(end, 'yyyy-MM-dd') : null);
    return (React.createElement("div", { className: 'range-picker' },
        React.createElement("i", null, MaterialIcons.today),
        React.createElement(DatePicker, { placeholderText: "\u6307\u5B9A\u306A\u3057", isClearable: isClearable, selectsRange: true, onChange: setRange, startDate: startDate, endDate: endDate, dateFormat: displayFormat, minDate: minDate },
            React.createElement(AdditionalControls, { range: [startDate, endDate], setRange: setRange }))));
});
const AdditionalControls = ({ range: [start, end], setRange }) => {
    const stayingNights = start && end ? differenceInCalendarDays(end, start) : '';
    return (React.createElement("div", { className: "additional-controls" },
        React.createElement("div", null,
            React.createElement("span", null, "\u5BBF\u6CCA\u6570"),
            React.createElement("input", { type: "number", value: stayingNights, onChange: ev => start && setRange([start, addDays(start, Number(ev.target.value))]) }),
            React.createElement("span", null, "\u6CCA")),
        React.createElement("div", null,
            React.createElement("span", null, "\u65E5\u4ED8\u672A\u5B9A"),
            React.createElement(ToggleSwitch, { value: !start, onChange: () => setRange([null, null]) }))));
};
export const argTypes = {
    from: {
        control: 'date'
    },
    to: {
        control: 'date'
    },
    displayFormat: {
        type: 'string'
    },
    minDate: {
        control: 'date'
    },
    onChange: {
        type: 'function'
    },
};
export const callbacks = (updateArgs) => ({
    onChange: (from, to) => updateArgs({ from: from ?? undefined, to: to ?? undefined })
});
