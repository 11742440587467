import { EntityFromSchema, $Schema, $EntityKeys } from '@~/domains/Entities/EntityFromSchema';
import Schema from '../schemas/Room.yml';
import { Picture } from './Picture';
import { deepCopy } from '../functions';
export class Room extends EntityFromSchema {
    [$Schema] = Schema;
    [$EntityKeys] = ['sales_inventory_id', 'house_id'];
    sales_inventory_id;
    house_id;
    display_name;
    guests_range;
    unavailable_periods;
    recommendation;
    article;
    features;
    introduction;
    pictureKeys;
    order_number;
    updated_at;
    constructor(data) {
        super();
        this.sales_inventory_id = data.sales_inventory_id;
        this.house_id = data.house_id;
        this.display_name = data.display_name ?? '';
        this.guests_range = deepCopy(data.guests_range) ?? { min: 1, max: 1 };
        this.unavailable_periods = deepCopy(data.unavailable_periods) ?? [];
        this.recommendation = deepCopy(data.recommendation) ?? { icon: '', title: '', content: '' };
        this.article = deepCopy(data.article) ?? { icon: '', title: '', content: '' };
        this.features = deepCopy(data.features) ?? [];
        this.introduction = data.introduction ?? '';
        this.pictureKeys = deepCopy(data.pictures) ?? [];
        this.order_number = data.order_number ?? -1;
        this.updated_at = data.updated_at ? new Date(data.updated_at) : new Date();
        this.validate();
    }
    toSerializable() {
        return {
            sales_inventory_id: this.sales_inventory_id,
            house_id: this.house_id,
            display_name: this.display_name,
            guests_range: { ...this.guests_range },
            unavailable_periods: deepCopy(this.unavailable_periods),
            recommendation: { ...this.recommendation },
            article: { ...this.article },
            features: deepCopy(this.features),
            introduction: this.introduction,
            pictures: [...this.pictureKeys],
            order_number: this.order_number,
            updated_at: this.updated_at.getTime(),
        };
    }
    equalTo(target) {
        return target.house_id === this.house_id;
    }
    get pictures() {
        return this.pictureKeys.map(p => new Picture(p));
    }
}
