import { asyncIterate } from '@~/domains/functions';
import { LoadFailed, LoadedHouses, LoadedHouseDetail, LoadedCalender, CatchOneTimeToken, LoadedSite, LoadedHost, LoadedTradingInformation } from '@~/web/Applications/Actions/LoadedActions';
import { HostsRepository } from '../Repositories/HostsRepository';
import { HousesRepository } from '../Repositories/HousesRepository';
import { PlansRepository } from '../Repositories/PlansRepository';
import { RoomsRepository } from '../Repositories/RoomsRepository';
import { SitesRepository } from '../Repositories/SitesRepository';
import { BookingRepository } from '../Repositories/BookingRepository';
import { AlertMessage } from '@~/components/Actions/MessageActions';
import { TentativeReservationRepository } from '../Repositories/TentativeReservationRepository';
import { ExecuteSearch } from '@~/web/@Components/Actions/SearchActions';
import { TransitionToReservation } from '@~/web/@Components/Actions/TransitionActions';
import { CompleteReserve } from '../Actions/ReserveActions';
export class LoadInformation {
    hosts;
    sites;
    houses;
    rooms;
    plans;
    booking;
    reservation;
    static initWithLoader(loader) {
        return new this(new HostsRepository(loader), new SitesRepository(loader), new HousesRepository(loader), new RoomsRepository(loader), new PlansRepository(loader), new BookingRepository(loader), new TentativeReservationRepository(loader));
    }
    constructor(hosts, sites, houses, rooms, plans, booking, reservation) {
        this.hosts = hosts;
        this.sites = sites;
        this.houses = houses;
        this.rooms = rooms;
        this.plans = plans;
        this.booking = booking;
        this.reservation = reservation;
    }
    async initiatePage(url, dispatch) {
        try {
            const ott = this.takeOneTimeToken();
            if (ott)
                dispatch(new CatchOneTimeToken(ott));
            const site = await this.sites.mostMatched(url);
            if (!site)
                throw new LoadFailed('サイト情報の読み込みに失敗しました');
            dispatch(new LoadedSite(site));
            const houses = await asyncIterate(this.houses.listOfHost(site.host_id));
            if (!houses.length)
                throw new LoadFailed('施設情報の読み込みに失敗しました');
            dispatch(new LoadedHouses(houses));
            const host = await this.hosts.load();
            if (!host)
                throw new LoadFailed('ホスト情報の読み込みに失敗しました');
            dispatch(new LoadedHost(host));
            const tradingIdAndHash = this.takeTradingId();
            if (tradingIdAndHash)
                this.tradingInformation(...tradingIdAndHash, dispatch);
        }
        catch (error) {
            console.error(error);
            const data = error instanceof LoadFailed
                ? error
                : new LoadFailed('施設情報の読み込みに失敗しました');
            dispatch(new LoadedHouses(data));
        }
    }
    takeOneTimeToken() {
        const url = new URL(location.href);
        const token = url.searchParams.get('ott');
        if (!token)
            return null;
        url.searchParams.delete('ott');
        history.replaceState(history.state, '', url);
        return token;
    }
    takeTradingId() {
        const url = new URL(location.href);
        const trading_id = url.searchParams.get('tid');
        const hash_code = url.searchParams.get('hc');
        if (!trading_id || !hash_code)
            return null;
        url.searchParams.delete('tid');
        url.searchParams.delete('hc');
        history.replaceState(history.state, '', url);
        return [trading_id, hash_code];
    }
    async houseDetail(house_id, first, last, dispatch) {
        try {
            const house = await this.houses.load(house_id);
            if (!house)
                throw new LoadFailed('施設情報の読み込みに失敗しました');
            const [rooms, plans, prices, calendar,] = await Promise.all([
                asyncIterate(this.rooms.listOfHouse(house)),
                asyncIterate(this.plans.listOfHouse(house)),
                asyncIterate(this.booking.priceGroupsOfHouse(house)),
                (first && last)
                    ? asyncIterate(this.booking.dateStateOfHouse(house, first, last))
                    : null,
            ]);
            dispatch(new LoadedHouseDetail({ house, rooms, plans, prices, calendar }));
        }
        catch (error) {
            console.error(error);
            const data = error instanceof LoadFailed
                ? error
                : new LoadFailed('施設情報の読み込みに失敗しました');
            dispatch(new LoadedHouseDetail(data));
        }
    }
    async priceCalender(house_id, first, last, dispatch) {
        try {
            const house = await this.houses.load(house_id);
            if (!house)
                throw new LoadFailed('料金カレンダーの読み込みに失敗しました');
            const calendar = await asyncIterate(this.booking.dateStateOfHouse(house, first, last));
            dispatch(new LoadedCalender(calendar));
        }
        catch (error) {
            console.error(error);
            const message = error instanceof LoadFailed
                ? error.message
                : '料金カレンダーの読み込みに失敗しました';
            dispatch(new AlertMessage(message));
        }
    }
    async tradingInformation(trading_id, hash_code, dispatch) {
        try {
            dispatch(new ExecuteSearch());
            const r = await this.reservation.tradingInformation(trading_id, hash_code);
            if (!r)
                return;
            dispatch(new TransitionToReservation(r.orderState.roomTypeId, r.orderState.planId));
            dispatch(new LoadedTradingInformation(trading_id, r.orderState));
            if (r.status === 'completed') {
                dispatch(new CompleteReserve());
            }
        }
        catch (err) {
            if (err instanceof Error) {
                dispatch(new AlertMessage(err?.message));
            }
        }
    }
}
