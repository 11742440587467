import { format, addDays } from 'date-fns';
import { ChangeSearchCondition, ExecuteSearch, ChangeResultsGroup, ChangeResultsOrder } from '@~/components/Actions/SearchActions';
import { TransitionToRoomDetail } from '@~/components/Actions/TransitionActions';
import { ClosePopup } from '@~/components/Actions/ReserveActions';
import { calculatorFromState, displayRoom } from './utility';
import { ChangeMonth, ChangeStaySchedule, SelectStaySchedule, CloseStaySchedule } from '@~/components/Actions/ChangeDateActions';
export default (pictureToUrl) => {
    return (action, previous) => {
        if (action instanceof ChangeSearchCondition) {
            return {
                ...previous,
                selectedHotel: action.selectedHotel,
                from: action.from?.getTime() ?? undefined,
                to: action.to?.getTime() ?? undefined,
                guestsInRooms: action.guestsInRooms
            };
        }
        const previousSearch = {
            shownGroup: previous.searchResult?.shownGroup ?? 'plans',
            order: previous.searchResult?.order ?? 'recommended',
            results: previous.searchResult?.results ?? null
        };
        if (action instanceof ExecuteSearch) {
            return {
                ...previous,
                searchResult: {
                    ...previousSearch,
                    results: null,
                },
                loaded: undefined
            };
        }
        if (action instanceof ChangeResultsGroup) {
            return {
                ...previous,
                searchResult: {
                    ...previousSearch,
                    shownGroup: action.shownGroup,
                }
            };
        }
        if (action instanceof ChangeResultsOrder) {
            return {
                ...previous,
                searchResult: {
                    ...previousSearch,
                    order: action.order,
                }
            };
        }
        if (action instanceof ClosePopup) {
            if (previous.reservationOrder
                &&
                    previous.reservationOrder.status !== 'complete') {
                return previous;
            }
            return {
                ...previous,
                reservation: undefined,
                reservationOrder: undefined,
                roomDetail: undefined,
                complete: undefined,
            };
        }
        if (action instanceof TransitionToRoomDetail) {
            const room = previous.loaded?.rooms.find(r => r.sales_inventory_id === action.roomTypeId);
            return {
                ...previous,
                roomDetail: room && displayRoom(room, pictureToUrl)
            };
        }
        if (action instanceof ChangeMonth) {
            if (!previous.reservation)
                return previous;
            const calculator = calculatorFromState(previous);
            return {
                ...previous,
                reservation: {
                    ...previous.reservation,
                    yearMonth: action.yearMonth,
                    priceList: calculator?.pricesInMonth(action.yearMonth) ?? null
                }
            };
        }
        if (action instanceof ChangeStaySchedule) {
            const from = new Date(action.checkinDate);
            const to = addDays(from, action.numberOfNights);
            return {
                ...previous,
                from: format(from, 'yyyy-MM-dd'),
                to: format(to, 'yyyy-MM-dd'),
            };
        }
        if (action instanceof SelectStaySchedule) {
            if (!previous.reservation)
                return previous;
            const from = new Date(action.checkinDate);
            const max = calculatorFromState(previous)?.availableNightsFrom(from);
            if (!max)
                return previous;
            return {
                ...previous,
                reservation: {
                    ...previous.reservation,
                    scheduleSelect: {
                        checkin: action.checkinDate,
                        nights: action.numberOfNights,
                        maxNights: max
                    },
                    showCalendarModal: false
                }
            };
        }
        if (action instanceof CloseStaySchedule) {
            if (!previous.reservation)
                return previous;
            return {
                ...previous,
                reservation: {
                    ...previous.reservation,
                    scheduleSelect: null,
                    showCalendarModal: false
                }
            };
        }
        return previous;
    };
};
