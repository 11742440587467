import { ViewAction } from "@~/components/Actions/ViewAction";
export class TransitionToReservation extends ViewAction {
    roomTypeId;
    planId;
    constructor(roomTypeId, planId) {
        super();
        this.roomTypeId = roomTypeId;
        this.planId = planId;
    }
}
export class ReturnToReservation extends ViewAction {
}
export class TransitionToGuestForm extends ViewAction {
    guestInfo;
    memberId;
    constructor(guestInfo, memberId) {
        super();
        this.guestInfo = guestInfo;
        this.memberId = memberId;
    }
}
export class TransitionToLoginForm extends ViewAction {
    afterAction;
    constructor(afterAction) {
        super();
        this.afterAction = afterAction;
    }
}
export class TransitionToRoomDetail extends ViewAction {
    roomTypeId;
    constructor(roomTypeId) {
        super();
        this.roomTypeId = roomTypeId;
    }
}
export class TransitionToCancel extends ViewAction {
    targetScheduleIndex;
    constructor(targetScheduleIndex) {
        super();
        this.targetScheduleIndex = targetScheduleIndex;
    }
}
export class TransitionToChangeReservationForm extends ViewAction {
    targetScheduleIndex;
    constructor(targetScheduleIndex) {
        super();
        this.targetScheduleIndex = targetScheduleIndex;
    }
}
export class CloseChangeScheduleModal extends ViewAction {
}
export class TransitionToAccountEdit extends ViewAction {
}
export class TransitionToAccountEmailEdit extends ViewAction {
}
export class TransitionToAccountPasswordEdit extends ViewAction {
}
export class TransitionToForgetPassword extends ViewAction {
}
export class TransitionToResetPassword extends ViewAction {
}
export class TransitionToReservationDetail extends ViewAction {
    code;
    constructor(code) {
        super();
        this.code = code;
    }
}
