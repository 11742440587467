import { ViewAction } from "@~/components/Actions/ViewAction";
export class Reserve extends ViewAction {
}
export class ClosePopup extends ViewAction {
}
export class ChangeTab extends ViewAction {
    selected;
    constructor(selected) {
        super();
        this.selected = selected;
    }
}
