const TypeGuardKey = Symbol();
export class ApplicationAction {
    [TypeGuardKey];
    static debugTrace = false;
    stack;
    constructor() {
        if (ApplicationAction.debugTrace) {
            const stack = new Error().stack?.split('\n').slice(2);
            if (!stack)
                return;
            const index = stack.findIndex(r => r.includes(this.constructor.name)) + 1;
            stack.splice(0, index, `[${this.constructor.name}] @Debug tracing Flux action.`);
            this.stack = stack.join('\n');
        }
    }
}
