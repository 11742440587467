const ErrorCode = {
    NotWellFormed: -32700,
    UnsupportedEncoding: -32701,
    InvalidCharacter: -32702,
    NotConformingToSpec: -32600,
    MethodNotFound: -32601,
    InvalidMethodParameters: -32602,
    InternalServerError: -32603,
    ApplicationError: -32500,
    SystemError: -32400,
    TransportError: -32300,
};
export class MessagingJsonRpcError extends Error {
    response;
    constructor(response) {
        super(response.message);
        this.response = response;
    }
    get code() {
        return this.response.code;
    }
}
export class MessagingJsonRpcServer {
    controller;
    isAllowed;
    listeners;
    constructor(controller, allowedOrigin) {
        this.controller = controller;
        this.isAllowed = allowedOrigin instanceof Function ? allowedOrigin : (origin) => origin === allowedOrigin;
        this.listeners = new WeakMap();
    }
    listen(target) {
        if (this.listeners.get(target))
            return;
        if ('window' in target) {
            const listener = (event) => {
                if (event.source === target)
                    this.onMessage(event, target);
            };
            self.addEventListener('message', listener);
            this.listeners.set(target, listener);
            return;
        }
        if (target instanceof MessagePort) {
            const listener = (event) => this.onMessage(event, target);
            target.addEventListener('message', listener);
            this.listeners.set(target, listener);
            return;
        }
    }
    remove(target) {
        const listener = this.listeners.get(target);
        if (!listener)
            return;
        this.listeners.delete(target);
        if (target instanceof Window) {
            self.removeEventListener('message', listener);
            return;
        }
        if (target instanceof MessagePort) {
            target.removeEventListener('message', listener);
            return;
        }
    }
    async onMessage(event, responseTarget) {
        try {
            if (!this.validation(event))
                return;
            const result = await this.controller[event.data.method](...(event.data.params ?? []));
            const data = {
                jsonrpc: '2.0',
                id: event.data.id,
                result
            };
            'window' in responseTarget
                ? responseTarget.postMessage(data, event.origin)
                : responseTarget.postMessage(data);
        }
        catch (error) {
            console.error(error);
            const code = error instanceof MessagingJsonRpcError
                ? error.code
                : ErrorCode.InternalServerError;
            const message = error instanceof Error
                ? error.message
                : `Throws ${typeof error}.`;
            const stack = error instanceof Error
                ? error.stack
                : undefined;
            responseTarget.postMessage({
                jsonrpc: '2.0',
                id: event.data.id,
                error: {
                    code,
                    message,
                    stack
                }
            });
        }
    }
    ;
    validation(event) {
        const { jsonrpc, id, method } = event.data ?? {};
        if (!jsonrpc)
            return false;
        if (!method)
            return false;
        if (!id) {
            throw new MessagingJsonRpcError({
                code: ErrorCode.NotConformingToSpec,
                message: 'id not found.'
            });
        }
        if (typeof method !== 'string' && typeof method !== 'number') {
            throw new MessagingJsonRpcError({
                code: ErrorCode.NotConformingToSpec,
                message: `Instance of ${typeof method} is not allowed to method.`
            });
        }
        if (!(this.controller[method] instanceof Function)) {
            throw new MessagingJsonRpcError({
                code: ErrorCode.MethodNotFound,
                message: `Method "${method}" is not Found.`
            });
        }
        if (!this.isAllowed(event.origin)) {
            throw new MessagingJsonRpcError({
                code: ErrorCode.TransportError,
                message: `Request from not allowed origin. (${event.origin})`
            });
        }
        return true;
    }
}
function randomBase64(byteLength) {
    const bytes = crypto.getRandomValues(new Uint8Array(byteLength));
    const asString = bytes.reduce((text, b) => text + String.fromCharCode(b), '');
    return btoa(asString).replace('=', '');
}
export class MessagingJsonRpcClient {
    target;
    constructor(target) {
        this.target = target;
        'window' in this.target
            ? self.addEventListener('message', this.onMessage)
            : this.target.addEventListener('message', this.onMessage);
    }
    close() {
        'window' in this.target
            ? self.removeEventListener('message', this.onMessage)
            : this.target.removeEventListener('message', this.onMessage);
    }
    post(data) {
        'window' in this.target
            ? this.target.postMessage(data, '*')
            : this.target.postMessage(data);
    }
    request(method, ...params) {
        return new Promise((resolve, reject) => {
            const id = randomBase64(32);
            this.promises[id] = { resolve, reject };
            this.post({
                jsonrpc: '2.0',
                id,
                method,
                params
            });
        });
    }
    promises = {};
    onMessage = ({ data, source }) => {
        if (source && source !== this.target)
            return;
        const { jsonrpc, id } = data ?? {};
        if (!jsonrpc || !id)
            return;
        const promise = this.promises[id];
        delete this.promises[id];
        if (!promise)
            return;
        if ('result' in data) {
            promise.resolve(data.result);
        }
        if ('error' in data) {
            const error = new MessagingJsonRpcError(data.error);
            promise.reject(error);
        }
    };
}
export class CrossOriginJsonRpcClient extends MessagingJsonRpcClient {
    allowedOrigins;
    constructor(target, allowedOrigins = []) {
        super(target);
        this.allowedOrigins = allowedOrigins;
    }
    addAllowedOrigin(origin) {
        this.allowedOrigins.push(origin);
    }
    post(data) {
        this.allowedOrigins.forEach(origin => {
            'window' in this.target && isCrossOrigin(this.target)
                ? this.target.postMessage(data, origin)
                : this.target.postMessage(data);
        });
    }
}
function isCrossOrigin(win) {
    try {
        return window.location.origin !== win.location.origin;
    }
    catch (e) {
        return false;
    }
}
