import { ApplicationAction } from './ApplicationAction';
export class SetupIframe extends ApplicationAction {
    iframe;
    constructor(iframe) {
        super();
        this.iframe = iframe;
    }
}
export class MessageFromIframe extends ApplicationAction {
    source;
    event;
    constructor(source, event) {
        super();
        this.source = source;
        this.event = event;
    }
}
