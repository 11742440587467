import { ApplicationAction } from "./ApplicationAction";
export class CompleteReserve extends ApplicationAction {
}
export class ErrorReserve extends ApplicationAction {
    message;
    constructor(message) {
        super();
        this.message = message;
    }
}
export class CompleteChangeSchedule extends ApplicationAction {
}
export class CompleteCancelSchedule extends ApplicationAction {
}
export class ErrorCancelSchedule extends ApplicationAction {
    message;
    constructor(message) {
        super();
        this.message = message;
    }
}
