import { EntityFromSchema, $Schema, $EntityKeys } from '@~/domains/Entities/EntityFromSchema';
import { deepCopy } from '../functions';
import Schema from '../schemas/DateState.yml';
const Keys = ['date'];
export class DateState extends EntityFromSchema {
    [$Schema] = Schema;
    [$EntityKeys] = Keys;
    date;
    fee_rank_id;
    inventories;
    plans;
    vacancies;
    constructor(data) {
        super();
        this.date = data.date;
        this.fee_rank_id = data.fee_rank_id ?? null;
        this.inventories = deepCopy(data.inventories) ?? [];
        this.plans = deepCopy(data.plans) ?? [];
        this.vacancies = deepCopy(data.vacancies) ?? [];
        // this.validate();
    }
    toSerializable() {
        return {
            date: this.date,
            fee_rank_id: this.fee_rank_id,
            inventories: deepCopy(this.inventories),
            plans: deepCopy(this.plans),
            vacancies: deepCopy(this.vacancies),
        };
    }
    equalTo(target) {
        return target.date === this.date;
    }
}
