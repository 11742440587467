import { EntityFromSchema, $Schema, $EntityKeys } from '@~/domains/Entities/EntityFromSchema';
import { deepCopy } from '../functions';
import Schema from '../schemas/PriceGroup.yml';
const Keys = ['id'];
export class PriceGroup extends EntityFromSchema {
    [$Schema] = Schema;
    [$EntityKeys] = Keys;
    id;
    unit;
    prices;
    children;
    targets;
    constructor(data) {
        super();
        this.id = data.id;
        this.unit = data.unit;
        this.prices = deepCopy(data.prices);
        this.children = deepCopy(data.children);
        this.targets = deepCopy(data.targets);
        this.validate();
    }
    toSerializable() {
        return {
            id: this.id,
            unit: this.unit,
            prices: deepCopy(this.prices),
            children: deepCopy(this.children),
            targets: deepCopy(this.targets),
        };
    }
    equalTo(target) {
        return target.id === this.id;
    }
}
