import { ApplicationAction } from './ApplicationAction';
export class UrlAction extends ApplicationAction {
    url;
    constructor(url) {
        super();
        this.url = url;
    }
    get query() {
        const params = this.url.searchParams;
        return Object.fromEntries([...params.keys()].map(key => [key, params.get(key)]));
    }
}
const InitiatePageKey = Symbol();
export class InitiatePage extends UrlAction {
    [InitiatePageKey];
}
const UpdateUrlKey = Symbol();
export class UpdateUrl extends UrlAction {
    [UpdateUrlKey];
}
const PopStateUrlKey = Symbol();
export class PopStateUrl extends UrlAction {
    [PopStateUrlKey];
}
