import { NotAllowedModification } from '../Exceptions/NotAllowedModification';
import { Host } from '@~/web/@Domains/Entities/Host';
export class HostsRepository {
    loader;
    constructor(loader) {
        this.loader = loader;
    }
    loadByMemberPageUrl(_) {
        throw new Error('Method not implemented.');
    }
    save(_host) {
        throw new NotAllowedModification();
    }
    remove(_host) {
        throw new NotAllowedModification();
    }
    // async * listOfHost(host_id: number) : AsyncIterable<House> {
    //     for await(const raw of this.loader.houses()) {
    //         if (raw.host_id !== host_id) continue;
    //         yield new House(raw);
    //     }
    // }
    // async save(house: House) : Promise<number> {
    //     throw new NotAllowedModification();
    // }
    async load() {
        const storedHost = await this.loader.host();
        return storedHost ? new Host(storedHost) : null;
    }
}
