import { ChildCategory } from "../Enums";
import { PriceNotDecidable } from "../Exceptions/CalculationExceptions";
import { sum } from "../functions";
import { format } from "date-fns";
export function mapGuests(guests, callback) {
    return Object.entries(guests).map(([categoryName, numGuest]) => {
        const childCategory = ChildCategory[categoryName];
        return callback(childCategory, numGuest);
    });
}
export class UnitPriceCalculator {
    priceGroups;
    calendar;
    constructor(priceGroups, calendar) {
        this.priceGroups = priceGroups;
        this.calendar = calendar;
    }
    /** @throws PriceNotDecidable */
    unitPrice(plan, room, guests, date, numberOfRooms) {
        try {
            return this.priceByRoom(plan, room, date, numberOfRooms);
        }
        catch (err) {
            if (!(err instanceof PriceNotDecidable)) {
                throw err;
            }
        }
        try {
            return this.priceByGuests(plan, room, guests, date);
        }
        catch (err) {
            if (!(err instanceof PriceNotDecidable)) {
                throw err;
            }
        }
        throw new PriceNotDecidable();
    }
    findPriceGroups(plan, room) {
        return this.priceGroups.find(group => group.targets.some(target => plan.plan_id === target.plan_id
            &&
                room.sales_inventory_id === target.sales_inventory_id)) ?? null;
    }
    /** @throws PriceNotDecidable */
    priceByRoom(plan, room, date, numberOfRooms) {
        const group = this.findPriceGroups(plan, room);
        if (group?.unit !== 'room')
            throw new PriceNotDecidable();
        const rank = this.feeRank(date, plan, room);
        const prices = group.prices.find(p => p.fee_rank_id === rank)?.rank_prices;
        if (!prices)
            throw new PriceNotDecidable();
        const max = Math.max(...prices.map(p => p.quantity));
        const unitPrice = prices.find(r => r.quantity === Math.min(numberOfRooms, max))?.unit_price ?? null;
        if (unitPrice === null)
            throw new PriceNotDecidable();
        return unitPrice;
    }
    /** @throws PriceNotDecidable */
    priceByGuests(plan, room, guests, date) {
        const group = this.findPriceGroups(plan, room);
        if (group?.unit !== 'guest')
            throw new PriceNotDecidable();
        const rank = this.feeRank(date, plan, room);
        const prices = group.prices.find(p => p.fee_rank_id === rank)?.rank_prices;
        if (!prices)
            throw new PriceNotDecidable();
        const pricingGuestsNumber = this.pricingGuestsNumber(group, guests);
        const max = Math.max(...prices.map(p => p.quantity));
        const baseUnitPrice = prices.find(r => r.quantity === Math.min(pricingGuestsNumber, max))?.unit_price ?? null;
        if (baseUnitPrice === null)
            throw new PriceNotDecidable();
        return sum(mapGuests(guests, (childCategory, numGuest) => numGuest
            ? numGuest * this.childUnitPrice(group, baseUnitPrice, childCategory)
            : 0));
    }
    feeRank(date, plan, room) {
        const dateState = this.calendar?.find(cal => cal.date === format(date, 'yyyy-MM-dd'));
        return dateState ? (dateState.plans.find(p => p.plan_id === plan.plan_id)?.fee_rank_id
            ??
                dateState.inventories.find(inv => inv.sales_inventory_id === room.sales_inventory_id)?.fee_rank_id
            ??
                dateState.fee_rank_id) : null;
    }
    childUnitPrice(group, baseUnitPrice, childCategory) {
        const adjustVolume = group.children.volumes.find(v => v.child_category === childCategory)?.value ?? null;
        if (adjustVolume !== null) {
            switch (group.children.adjustBy) {
                case 'subtract': return baseUnitPrice - adjustVolume;
                case 'percent': return Math[group.children.roundingBy](baseUnitPrice * adjustVolume / 100);
                case 'override': return adjustVolume;
            }
        }
        return baseUnitPrice;
    }
    pricingGuestsNumber(group, guests) {
        if (group.children.adjustBy !== 'override')
            return sum(Object.values(guests));
        // 単価を上書きする子供料金は値付け時に参照する数量から除外するが、単価が指定されていない子供区分の場合（値段がnull）は大人単価を参照するため除外しない
        return sum(mapGuests(guests, (childCategory, numGuest) => {
            const adjustVolume = group.children.volumes.find(v => v.child_category === childCategory)?.value ?? null;
            return adjustVolume === null ? numGuest : 0;
        }));
    }
}
